.switch-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &.switch-on{
    .switch-last-label{
      color: #4677f0;
      img{
        filter: brightness(0) saturate(100%) invert(40%) sepia(67%) saturate(2434%) hue-rotate(210deg) brightness(97%) contrast(94%);
      }
    }
  }
  &.switch-off{
    .switch-first-label{
      color: #4677f0;
      img{
        filter: brightness(0) saturate(100%) invert(40%) sepia(67%) saturate(2434%) hue-rotate(210deg) brightness(97%) contrast(94%);
      }
    }
  }
  .switch {
    display: inline-block;
    font-size: 20px;
    position: relative;
    cursor: pointer;
    width: 42px;
    height: 23px;
    border-radius: 11.5px;
    background: #e2eaff;
    > input.switch_check {
      position: absolute;
      opacity: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
      z-index: 2;
      left: 0;
      top: 0;
    }
    > .circle {
      width: 15px;
      height: 15px;
      background: #4677f0;
      border-radius: 20px;
      cursor: pointer;
      margin-top: 0;
      position: absolute;
      top: 4px;
      left: 5px;
      box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      transition: all 300ms;
    }
    input.switch_check:checked + .circle {
      -webkit-transform: translate3d(115%, 0, 0);
      -moz-transform: translate3d(115%, 0, 0);
      transform: translate3d(115%, 0, 0);
    }
  }
  > label {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: var(--text);
    margin-left: 7px;
    img{
      transition: all 0.5s ease 0s;
    }
  }
}
