$NormalScreen: 1600px;
$MdTablet: 1200px;

.PageNotFound {
    background-color: #fff;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
    .pagenot-img{
        line-height: 0;
        max-width: 680px;
        @media (max-width: $NormalScreen) {
            max-width: 550px;
        }
    }
    .pagenot-content{
        text-align: center;
        h4{
            font-family: "Merriweather Sans";
            font-weight: normal;
            font-size: 36px;
            text-align: center;
            color: #242424;
            line-height: 130%;
            @media (max-width: $NormalScreen) {
                font-size: 28px;
            }
        }
        span{
            font-family: "Merriweather Sans";
            font-weight: normal;
            text-decoration: underline;
            font-size: 22px;
            text-align: center;
            color: var(--blue);
            @media (max-width: $NormalScreen) {
                font-size: 18px;
            }
            &:hover{
                color: #232323;
                cursor: pointer;
            }

        }
    }
}