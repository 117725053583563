
@import '../../style.scss';

.border-top {
	padding-top: 30px;
	margin-top: 10px;
	border-top: 1px solid #ccc;
}
.followup-reminder-setting{
    .label-with-time{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
        label{
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            color: var(--text-black);
            padding-right: 25px;
            min-width: 335px;
        }
        .label-image{
            width: 15px;
            height: 15px;
            margin-left: 5px;
            line-height: 0;
        }
    }
}