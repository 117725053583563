@import "../../../../style.scss";

.EditDigitalSignature-popup {
    .modalBox {
        div.modalMain {
            max-width: 950px;
            width: 100%;
        }
    }

    .SignModal {
        display: flex;
        flex-wrap: wrap;

        .upload-edit-sign {
            max-width: 55%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .switch-wrap {
            >label {
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
                color: #242424;

                @media (max-width: $NormalScreen) {
                    font-size: 14px;
                }
            }

            &.switch-off {
                .switch {
                    background-color: #EAEAEA;

                    .circle {
                        background-color: #A5A5A5;
                    }
                }
            }
        }
    }

    .uploadSign {
        margin-bottom: 30px;

        .uploadlogo {
            margin-top: 15px;
            padding-left: 55px;

            .upload {
                border: 1px dashed #A5A5A5;
            }

            .upload-button {
                width: 100%;
                max-width: 100%;
            }
        }

        .note {
            display: block;
            text-align: right;
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            color: #67757C;
            margin-top: 5px;
        }
    }

    .editSign {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        .edit-signature-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            margin-top: 15px;
            padding-left: 56px;
            max-width: 260px;

            h6 {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #D9D9D9;
                margin-bottom: 5px;
            }

            .no-label {
                width: 100%;

                label {
                    display: none;
                }
            }

            .formField {
                display: flex;
                align-items: center;
                margin-bottom: 5px;

                >label {
                    min-width: 60px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #D9D9D9;
                }

                >input {
                    padding: 0;
                    height: 32px;
                    font-weight: normal;
                    border-width: 0 0 1px 0;
                    border-radius: 0;
                }
            }
        }
    }

    .preview-sign {
        background: #F7F7F7;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15);
        max-width: 45%;
        width: 100%;
        min-height: 443px;
        position: relative;
        padding: 15px;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 15px;
            right: 15px;
            bottom: 15px;
            height: 1px;
            background-color: #D9D9D9;
        }

        .page-header {
            position: absolute;
            top: 15px;
            z-index: 1;
            pointer-events: auto;
            visibility: visible;
            left: 0;
            right: 0;
            margin: auto;
            width: auto;
            opacity: 0.5;
            transform: scale(0.92);

            .header-preview {
                padding: 0;
            }
        }

        .preview-data {
            position: absolute;
            bottom: 15px;
            right: 15px;
            text-align: left;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: 10px;
            padding-left: 15px;

            .sign-data,
            .sign-prev {
                width: auto;
                text-align: left;
            }

            .sign-prev {
                margin-bottom: 10px;

                img {
                    max-height: 40px;
                }
            }

            .sign-data {
                h6 {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 15px;
                    color: #000;
                    margin-bottom: 8px;
                }

                p {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 120%;
                    color: #242424;
                    margin-bottom: 8px;
                }
            }
        }
    }
}