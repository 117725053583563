@import '../../style.scss';

.table {

    &.fullheight-table,
    &.vitals,
    &.notifications,
    &.laboratorytests,
    &.moduleAccesses,
    &.userroles,
    &.canvasIcon,
    &.onboardvideos,
    &.whatsnew,
    &.multiLingual,
    &.payments,
    &.symptoms,
    &.diseases,
    &.hospitals {
        .ant-table-wrapper {
            max-height: calc(100vh - 334px);
            min-height: calc(100vh - 334px);
            overflow: auto;

            @media (max-width: $NormalScreen) {
                max-height: calc(100vh - 300px);
                min-height: calc(100vh - 300px);
            }
        }
    }

    .ant-switch {
        min-width: 29px;
        height: 16px;

        .ant-switch-handle {
            width: 12px;
            height: 12px;
        }
    }

    .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 12px - 2px);
    }

    .ant-dropdown-trigger {
        margin-left: 10px;
        cursor: pointer;
    }

}

.feildRequired span {
    display: inline-block;
    margin-left: 2px;
}

.contactformField {
    display: flex;
    flex-wrap: wrap;

    div.react-select__control:hover {
        border-color: var(--bordercolor);
    }

    .react-select__control {
        min-width: 1px;
        width: 71px;
        margin-right: -1px;
        border-radius: 4px 0 0 4px;
        background-color: #FAFAFA;

        .react-select__value-container {
            padding: 0 2px 0 10px;

            .react-select__single-value {
                margin: 0;
            }
        }

        .react-select__indicators {
            .react-select__dropdown-indicator {
                padding: 0 5px 0 0;
            }
        }
    }

    .ex-label {
        width: 100%;

        label {
            font-family: "Merriweather Sans";
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            color: #4f4f4f;
            min-height: 20px;
            display: block;
            margin-bottom: 8px;
        }
    }

    .formField {
        &.countrycode {
            width: 53px;
            margin-right: 5px;

            input {
                padding-left: 10px;
            }

            label {
                display: none;
            }

            +.usernumber {
                width: calc(100% - 58px);

                input {
                    border-radius: 4px;
                    padding-right: 25px;
                }
            }
        }

        &.usernumber {
            width: calc(100% - 70px);

            input {
                border-radius: 0 4px 4px 0;
            }

            >.icon {
                position: absolute;
                top: 7px;
                right: 7px;
                z-index: 1;
            }
        }
    }
}


.add-hospital-modal.AddNew {
    .modalMain {
        max-width: 1100px;
        width: 100%;

        .ant-tabs {
            .ant-tabs-content-holder {
                height: calc(100vh - 410px);
            }

            .ant-tabs-nav {
                display: inline-flex;
                max-width: 100%;
                width: auto;
                border: none;
                margin-bottom: 30px;
            }

            .ant-tabs-nav-list {
                background-color: #F5F5F5;

                .ant-tabs-tab {
                    padding: 12px 24px;

                    &:hover,
                    &.ant-tabs-tab-active {
                        background: #231F20;
                    }
                }
            }
        }

        .d-flex {
            display: flex;
            justify-content: space-between;

            >div {
                width: 33.33%;
                margin-right: 2.5%;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .feildRequired span {
            margin-top: 0;
        }
    }


    .pricing-title {
        h4 {
            font-size: 16px;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: 0.32px;
            margin-bottom: 15px;
        }
    }

    .doccine-watermark {
        display: flex;
        padding: 10px 62px 10px 12px;
        flex-direction: column;
        border-radius: 5px;
        border: 1px solid #CFCFCF;
        background: #FCFCFC;
        position: relative;
        margin-bottom: 16px;

        .switch-btn {
            position: absolute;
            top: 17px;
            right: 12px;
        }

        h6 {
            font-size: 14px;
            font-weight: 600;
            line-height: 157%;
            letter-spacing: 0.28px;
            margin-bottom: 0;
        }

        p {
            color: #464243;
            font-size: 12px;
            line-height: 133%;
            letter-spacing: 0.24px;

        }
    }

    .pricing-plan {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 0px 20px;
        border-radius: 3px;
        border: 1px solid #CFCFCF;
        margin-bottom: 16px;

        .checkbox {
            >label {
                margin-bottom: 0;

                &::before {
                    top: 2px;
                }
                display: inline-block;
            }
        }

        .react-datepicker-wrapper {
            margin-bottom: 0;
            width: 100%;

            input[disabled] {
                height: auto;
                display: flex;
                padding: 8px 12px;
                border: 1px solid #D9D9D9;
                background: #F5F5F5;
                color: rgba(0, 0, 0, 0.25);
                font-size: 16px;
                font-weight: 400;
                line-height: 150%;
                border-radius: 4px;
                font-weight: 300;
            }
        }
    }

    .pruchase-date-notchange {
        margin: 0 -20px;
        padding: 12px 32px;
        justify-content: space-between;
        align-items: center;
        background: rgba(232, 176, 92, 0.08);
        display: flex;
        position: relative;



        >span {
            color: #464243;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.28px;

            b {
                font-weight: 600;
                color: #231F20;
            }

            +span {
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 1px;
                    height: 17px;
                    background-color: #AEA9A2;
                    margin: auto;
                    pointer-events: none;
                }
            }
        }
    }

    .balance-credit {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .balance-box {
            max-width: 473px;
            width: 100%;
            border-radius: 3px;
            border: 1px solid #CFCFCF;
            background: #FFF;
            padding: 20px 20px 0px 20px;
        }

        .balance-title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;

            h4 {
                color: #231F20;
                font-size: 16px;
                font-weight: 500;
                line-height: 137%;
                letter-spacing: 0.32px;
                margin-bottom: 0;
            }

            >span {
                color: #8B8989;
                text-align: right;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                letter-spacing: 0.24px;
            }
        }

        .ant-progress-inner {
            height: 12px;
            background: #F3F8FF;

            .ant-progress-bg {
                min-height: 100%;
            }
        }

        .purchase-btn {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 24px;

            .formField {
                margin-bottom: 0;
                max-width: 250px;
                width: 100%;

                label {
                    color: rgba(0, 0, 0, 0.85);
                    font-weight: 300;
                }
            }

            .button {
                button {
                    font-size: 12px;
                    width: 165px;
                    padding: 12px 10px;

                    &[disabled]{
                        background-color: #F2F2F2;
                        color: #8B8989;
                        pointer-events: none;
                        cursor: not-allowed;
                    }
                    
                }
            }
        }
    }



    .table.member-list {
        .ant-table-tbody>tr>td {
            padding: 8px 10px;
        }

        .icon {
            margin-right: 5px;
            display: inline-block;
            vertical-align: top;
            width: 21px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .activeButton {
        align-items: center;

        >.button:first-child {
            margin-left: 0;
            margin-right: auto;
        }

        .formField.checkbox {
            margin-bottom: 0;
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            label {
                margin-bottom: 0;
            }
        }
    }

    .roleModuleAccess {
        .activeButton>.button:first-child {
            margin-left: auto;
            margin-right: 0;
        }
    }

}