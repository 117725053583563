@import '../../style.scss';

.adminSidebar {
    max-width: 250px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    overflow: auto;
    background: var(--white);
    padding-top: 100px;
    transition: all 0.5s ease;
    box-shadow: 0 1px 4px -1px rgba(0,0,0,.15);
    @media (max-width: $NormalScreen) {
        max-width: 210px;
    }
    .logo{
        padding: 13px 30px;
        border-bottom: 1px solid var(--bordercolor);
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: sticky;
        top: 0;
        img{
            max-width: 140px;
        }
    }
}

.menuList {
    padding:0;
    margin:0;
    max-height: 100%;
    overflow: auto;
    li {
        position: relative;
        cursor: pointer;
        &.show-sub-menu{
            &.active{
                > .menuBox {
                    background: transparent;
                    span{
                        color: var(--blue);
                        >img {
                            filter: none;
                            opacity: 1;
                        }
                    }
                    &:after{
                        display: none;
                    }
                }
            }
        }
        &.active{
            > .menuBox {
                background: rgba(62,121,247,.1);
                &:after{
                    transform: scaleY(1);
                    opacity: 1;
                    transition: transform .15s cubic-bezier(.645,.045,.355,1),opacity .15s cubic-bezier(.645,.045,.355,1);
                    transition: transform .15s cubic-bezier(.645,.045,.355,1),opacity .15s cubic-bezier(.645,.045,.355,1),-webkit-transform .15s cubic-bezier(.645,.045,.355,1);
                }
            }
            
        }
        .menuBox {
            padding: 12px 30px;
            position: relative;
            display: block;
            transition: border-color .3s,background .3s,padding .1s cubic-bezier(.215,.61,.355,1);
            @media (max-width: $NormalScreen) {
                padding: 12px 20px;
            }
            &:active{
                background: rgba(62,121,247,.1);
            }
            &:after{
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                border-right: 3px solid #3e79f7;
                -webkit-transform: scaleY(.0001);
                transform: scaleY(.0001);
                opacity: 0;
                transition: transform .15s cubic-bezier(.215,.61,.355,1),opacity .15s cubic-bezier(.215,.61,.355,1);
                transition: transform .15s cubic-bezier(.215,.61,.355,1),opacity .15s cubic-bezier(.215,.61,.355,1),-webkit-transform .15s cubic-bezier(.215,.61,.355,1);
    
            }
            &:hover{
                p,
                span {
                    color: var(--blue);

                    >svg {
                        filter: none;
                        opacity: 1;
                    }
                }
                background: rgba(62,121,247,.1);
            }
            
            span {
                position: relative;
                display: block;
                font-weight: 400;
                line-height: 26px;
                text-align: left;
                color: #455560;
                font-size: 14px;
                padding-left: 30px;
                white-space: nowrap;
                @media (max-width: $NormalScreen) {
                    font-size: 14px;
                    line-height: 20px;
                    padding-left: 25px;
                }

                >svg {
                    position: absolute;
                    left: 0;
                    top: 4px;
                    filter: grayscale(100%);
                    opacity: 1;
                    width: 18px;
                    height: 21px;
                    @media (max-width: $NormalScreen) {
                        top: 0;
                    }
                }
            }

            p {
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                text-align: left;
                color: var(--menu);
                width: 152px;
                display: none;
            }
        }

        &.show-sub-menu{
            .submenu-sidebar{
                max-height: 600px;
                transition: all 0.5s ease;
            } 
            .menu-submenu-arrow{
                top: 24px;
                @media (max-width: $NormalScreen) {
                    top: 20px;
                }
                .submenu-arrow{
                    &:after{
                        transform: rotate(-45deg) translateX(-2.5px);
                    }
                    &:before {
                        transform: rotate(45deg) translateX(2.5px);
                    }
                }
            }
        }
        .submenu-sidebar{
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s ease;
            &:empty{
                display: none;
            }
            li{
                .menuBox{
                    padding: 5px 30px;
                    @media (max-width: $NormalScreen) {
                        padding: 8px 20px;
                    }
                    span {
                        font-weight: 300;
                      }
                }
            }
        }
        .menu-submenu-arrow{
            position: absolute;
            top: 26px;
            right: 15px;
            width: 10px;
            height: 10px;
            color: #455560;
            pointer-events: none;
            @media (max-width: $NormalScreen) {
                top: 23px;
                right: 15px;
            }
            > i{
                width: 100%;
                height: 100%;
                display: block;
            }
            .submenu-arrow{
                &:after,
                &:before {
                   content: "";
                    position: absolute;
                    width: 6px;
                    height: 1.5px;
                    background-color: #000;
                    border-radius: 2px;
                    transition: background .3s cubic-bezier(.645,.045,.355,1),transform .3s cubic-bezier(.645,.045,.355,1),top .3s cubic-bezier(.645,.045,.355,1),color .3s cubic-bezier(.645,.045,.355,1);
                    transition: background .3s cubic-bezier(.645,.045,.355,1),transform .3s cubic-bezier(.645,.045,.355,1),top .3s cubic-bezier(.645,.045,.355,1),color .3s cubic-bezier(.645,.045,.355,1),-webkit-transform .3s cubic-bezier(.645,.045,.355,1);
                }
                &:before{
                    transform: rotate(-45deg) translateX(2.5px);
                }
                &:after {
                    transform: rotate(45deg) translateX(-2.5px);
                }
            }
        }
    }
}