@import '../../../style.scss';

.hdrgeneralinfo-contact-full-box {
    position: relative;
    padding-right: 30px;
    display: inline-block;

    .add-new-row-btn {
        position: absolute;
        right: 0;
        bottom: 9px;
        width: 21px;

        &:first-child {
            bottom: 0;
        }
    }

    .hdrgeneralinfo-contact-num {
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        &:last-of-type {
            margin-bottom: 0;
        }

        >.formField {
            margin-right: 15px;
        }
    }
}

.add-new-row-btn {
    display: block;
    line-height: 0;
    cursor: pointer;
}

.remove-new-row-btn {
    cursor: pointer;
}