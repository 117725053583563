.doctor-setting-wrap {
    >.adminTableHeader {
        display: flex;
        padding: 15px;
        background: #fff;
        margin-bottom: 15px;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--bordercolor);
        z-index: 5;
        position: relative;
    }
}

.EditProfileDetail-popup {
    .modalBox {
        .modalMain {
            max-width: 750px;
        }
    }
}

.secondary-contact-add-block {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 15px;
    width: 100%;

    .secondary-contact-add-field-list {
        width: 100%;

        .secondary-contact-add-field-row {
            resize: none;
            position: relative;
            margin-bottom: 15px;

            .secondary-contact-add-fields {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                width: auto;
                padding-right: 30px;

                .formField {
                    width: 43.2%;
                    margin-bottom: 0;
                    margin-right: 10px;

                    &.countrycode {
                        width: 60px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    label {
                        display: none;
                    }

                    input {
                        max-width: 100% !important;
                    }
                }
            }

            span.remove-vitals-single-row-btn {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                cursor: pointer;
                background: #DE350B;
                width: 20px;
                height: 20px;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1px;

                &:hover {
                    opacity: 0.75;
                }
            }

            &:last-child {
                .remove-vitals-single-row-btn {
                    display: none;
                }
            }
        }
    }

    .add-vitals-single-row-btn {
        position: absolute;
        right: 0;
        bottom: 40px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        line-height: 0;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            opacity: 0.75;
        }
    }
}

.DocProfilePopup-div {
    .formField.contactformField {
        display: flex;

        label {
            width: 100%;
        }

        >input {
            width: calc(100% - 70px);
        }
    }
}

.hosptial-other-info {
    margin-top: 30px;

    h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 0.32px;
    }

    .other-info-list {
        li {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 16px;

            p {
                margin-right: 15px;
                font-size: 14px;
            }

            .box-list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                >span {
                    display: flex;
                    padding: 4px 8px;
                    align-items: center;
                    border-radius: 2px;
                    border: 1px solid #D9D9D9;
                    background: #FFF;
                    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
                    margin-right: 10px;
                    color: rgba(0, 0, 0, 0.85);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}