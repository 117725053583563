.Opd_Settings {
    .table {
        .ant-table-wrapper {
            min-height: 1px;
            max-height: unset;
        }
    }

    .tableTitle {
        margin-bottom: 15px;
    }

    .consultation-fee {
        margin-bottom: 0;
        border: 1px solid var(--bordercolor);
        text-align: right;
        border-radius: 0;

        &.adminTable {
            padding: 0;
        }

        .language-table {
            .table {
                table {
                    td {
                        .action {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }

        tr:nth-last-child(2),
        tr:last-child {
            .react-select__menu {
                position: absolute;
                bottom: 0;
                top: auto;
            }
        }
        tr:nth-child(3),
        tr:nth-child(2) {
            div.react-select__menu {
                position: absolute;
                top: 100%;
                bottom: auto;
            }
        }
    }

    .add {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 15px;
        background: rgba(0, 0, 0, 0.04);

        >.label-name {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-family: "Merriweather Sans";
            font-size: 16px;
            line-height: 20px;
            color: var(--blue);
            cursor: pointer;

            >img {
                margin-left: 10px;
                width: 20px;
                height: 20px;
                transition: all 0.5s ease;
            }

            &:hover {
                color: #67757c;

                >img {
                    transform: rotate(180deg);
                    transition: all 0.5s ease;
                }
            }
        }
    }
}

.adminTable {
    .language-table {
        .table {
            table {
                tr.ant-table-row:hover {
                    >td {
                        background-color: #fff;
                    }
                }

                td {
                    border-bottom: 1px solid #f0f0f0;
                    border-left: 1px solid #f0f0f0;
                    border-right: 1px solid #f0f0f0;
                    padding: 0;

                    div.react-select__control {
                        border: none;
                        border-radius: 0;
                        box-shadow: none !important;

                        .react-select__value-container {
                            padding: 2px 13px;
                        }

                        &.react-select__control--is-focused {
                            background: #f4f4f4;
                            border: none;
                        }
                    }

                    &:first-child {
                        padding: 0 15px;
                    }

                    .formField {
                        margin: 0;

                        div.react-select__control,
                        input {
                            border: none;
                            border-radius: 0;
                            padding: 0 15px;
                        }

                        input {
                            padding: 0 15px;
                        }

                        &.active {
                            input {
                                background: #f4f4f4;
                            }
                        }
                    }

                    .action {
                        display: flex;
                        padding: 0 21px;
                        align-items: center;
                        justify-content: flex-end;

                        span {
                            cursor: pointer;
                            width: 26px;
                            height: 26px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 0 5px;

                            img {
                                filter: grayscale(1);
                                max-width: 26px;
                                max-height: 26px;
                                transition: all 0.3s ease;
                                opacity: 0.8;
                            }

                            &.add-group {
                                display: none;
                            }
                        }

                        &:hover {
                            span {
                                img {
                                    filter: none;
                                    opacity: 1;
                                    transform: scale(1.05);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}