@import "../../../../style.scss";

.tab-list{
    display: flex;
    flex-wrap: wrap;
    .TabInTab{
        min-width: 1px;
        padding: 0 20px;
        height: 42px;
        border-radius: 4px;
        background: #fff;
        border: 1px solid #d9d9d9;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        cursor: pointer;
        &:last-child{
            margin-right: 0;
        }
        &:hover,
        &.active{
            border-color: var(--blue);
            p{
                color: var(--blue);
                opacity: 1;
            }
        }
        p{
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #67757c;
            opacity: 1;
            @media (max-width: $NormalScreen) {
                font-size: 12px;
            }
        }
    }
}
