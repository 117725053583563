@import '../../style.scss';

.pricing-setting {
	display: flex;
	flex-wrap: wrap;
    .adminTable {
        margin-right: 1.5%;
        width: 32.1%;
        margin-bottom: 25px;
        position: relative;
        padding-bottom: 83px;
        &:nth-child(3n){
            margin-right: 0;
        }
        .usernumber {
            width: 100%;
        }
        .activeButton{
            position: absolute;
            bottom: 20px;
            left: 20px;
            right: 20px;
          }
    }
}