@import "../../style.scss";





.modalBox {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    pointer-events: none;
    transition: all 0.5s ease;
    // animation: modalfadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

    &.center {
        justify-content: center;
        text-align: left;
        .text-center{
            text-align: center;
            display: block;
        }
        >.modalMain {
            right: auto;
            max-height: 95vh;
            min-height: 45px;
            border-radius: 12px;
            // animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }

        &.show-modal {
            >.modalMain {
                right: auto;
                // animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            }
        }
    }
    &.right{
        >.modalMain {
            .modal-inner-scroll {
                height: calc(100vh - 140px);
            }
        }
    }

    &.show-modal {
        opacity: 1;
        visibility: visible;
        z-index: 99;
        pointer-events: auto;
        transition: all 0.5s ease;

        >.modalMain {
            transition: all 0.5s ease;
            right: 0;
        }
    }

    .outerClick {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: transparent;
        z-index: 0;
    }

    .close {
        position: absolute;
        top: 23px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: all 0.5s ease;
        display: block;
        background: #fff;
        right: 13px;
        z-index: 9;
        margin-top: 0;
        margin-right: 0;

        img {
            display: block;
        }

        &:hover {
            transform: rotate(-90deg);
            transition: all 0.5s ease;
        }
    }

    h3 {
        clear: both;
        width: 100%;
        font-family: "Merriweather Sans";
        font-weight: bold;
        font-size: 20px;
        line-height: 130%;
        color: #242424;
        margin-bottom: 0;
        border-bottom: 1px solid var(--bordercolor);
        text-align: left;
        padding: 20px 30px;

        @media (max-width: $NormalScreen) {
            font-size: 16px;
        }

        &:empty {
            display: none;
        }
    }

    p {
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
        color: #242424;

        @media (max-width: $NormalScreen) {
            font-size: 14px;
        }

        &.text-center {
            text-align: center;
        }

        &.text-left {
            text-align: left;
        }

        &.text-right {
            text-align: right;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

    }

    .modalMain {
        position: relative;
        max-width: 50%;
        min-width: 530px;
        max-height: 100vh;
        min-height: 100vh;
        background: #fff;
        box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.05);
        overflow: hidden;
        padding-bottom: 80px;
        padding-top: 0;
        transition: all 0.5s ease;
        right: -50%;
        // animation: fromright 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

        .modal-inner-scroll {
            max-height: calc(100vh - 170px);
            overflow: auto;
            padding: 25px 30px;
        }
    }

    .activeButton {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        bottom: 0;
        background: #fff;
        padding: 15px 38px;
        border-top: 1px solid #EAEAEA;
        margin: 0;
        left: 0;
        right: 0;
        z-index: 18;

        &.center {
            justify-content: center;
        }

        &.left {
            justify-content: flex-start;
        }

        &.right {
            justify-content: flex-end;
        }

        &.space-btn {
            justify-content: space-between;
        }

        &.with-download-pdf {
            justify-content: space-between;
            align-items: center;

            .download-pdf {
                font-weight: 700;
                font-size: 16px;
                color: var(--blue);
                cursor: pointer;

                @media (max-width: $NormalScreen) {
                    font-size: 14px;
                }

                &:hover {
                    color: #67757C;

                    svg {
                        transform: rotate(-90deg);
                        transition: all 0.5s ease;
                    }
                }

                svg {
                    margin-left: 6px;
                    transition: all 0.5s ease;
                    margin-top: -3px;
                }
            }
        }

        .button {
            margin: 0 10px;

            &:first-child {
                margin-right: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            button[type="reset"] {
                &:hover {
                    background-color: #e3ebf7;
                    color: var(--blue);
                }
            }
        }
    }
}

/*full wdith modal*/
.medium-modal{
    .modalBox {
        .modalMain {
            max-width: 1200px;
            width: 100%;
        }
    }
}

.full-modal{
    .modalBox {
        .modalMain {
            max-width: 98%;
            width: 100%;
        }
    }
}
/*full wdith modal end*/

/*alet modal icon animation*/
.modal-action-icon {
    >.modal-icon {
        position: relative;
        box-sizing: border-box;
        width: 70px;
        height: 70px;
        border: 3px solid transparent;
        border-radius: 50%;
        border-color: #d0ddfd;
        font-family: inherit;
        line-height: 5em;
        animation: modal-icon .5s;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;

        img {
            width: 36px;
            max-height: 36px;
            animation: modal-icon-img .5s;
        }
    }
}

.modal-action-info {
    p {
        font-size: 16px;
        line-height: 160%;
        letter-spacing: 0.3px;
        display: inline;
        text-align: center;
        em {
            font-style: normal;
            font-weight: 500;
        }
        strong {
            font-size: 18px;
            font-weight: bold;
        }
    }
}

@keyframes modal-icon {
    0% {
        transform: rotateX(100deg);
        opacity: 0
    }

    100% {
        transform: rotateX(0);
        opacity: 1
    }
}

@keyframes modal-icon-img {
    0% {
        transform: rotateZ(45deg);
        opacity: 0
    }

    25% {
        transform: rotateZ(-25deg);
        opacity: .4
    }

    50% {
        transform: rotateZ(15deg);
        opacity: .8
    }

    75% {
        transform: rotateZ(-5deg);
        opacity: 1
    }

    100% {
        transform: rotateX(0);
        opacity: 1
    }
}



@keyframes modalfadeIn {
    0% {
        background: rgba(0, 0, 0, 0);
    }

    100% {
        background: rgba(0, 0, 0, 0.7);
    }
}

@keyframes modalfadeOut {
    0% {
        background: rgba(0, 0, 0, 0.7);
    }

    100% {
        background: rgba(0, 0, 0, 0);
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scaleDown {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.8);
        opacity: 0;
    }
}

@keyframes fromright {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}