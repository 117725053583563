@import '../../style.scss';

.modalBox.vitals_add>.modalMain {
    min-width: 40%;
}

.vitals-checkboxes{
    display: flex;
    .formField.checkbox {
        margin-right: 15px;
    }
}

.vitals_add {
    .App {
        text-align: left;
    }

    .react-form-builder {
        .react-form-builder-preview {
            min-height: 350px;
            box-shadow: none;
            background-color: #f5f8ff;
            border: 1px solid var(--bordercolor);
        }

        .react-form-builder-toolbar {
            margin-top: 0;

            ul li {
                cursor: pointer;
                list-style: none;
                margin: 7px 0;
                padding: 10px;
                border: 1px dashed #ddd;
                text-align: left;
                font-size: 12px;

                &:hover {
                    background-color: #f5f8ff;
                }
            }

            h4 {
                padding: 10px;
                font-size: 14px;
                background-color: #f5f8ff;
                margin-bottom: 10px;
                text-align: left;
            }
        }

        .react-form-builder-preview {
            .Sortable {
                .rfb-item {
                    padding: 15px;
                    background: rgba(0, 0, 0, 0.1);
                    text-align: left;
                }

                >div {
                    margin-bottom: 6px;
                }

                h3 {
                    font-size: 16px;
                    padding: 5px;
                    text-overflow: ellipsis;
                    width: 100%;
                    display: block;
                    overflow: hidden;
                }

                .toolbar-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    .toolbar-header-buttons {
                        width: 50%;
                        display: flex;
                        justify-content: flex-end;
                        right: 0;
                        margin-top: -4px;

                        .btn {
                            padding: 2px;
                            line-height: 1.5;
                            font-size: 14px;
                            height: 22px;
                            width: 22px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 0 2px;
                            color: var(--blue);

                            &:hover {
                                color: var(--black);
                            }
                        }
                    }
                }
            }

            &.is-editing {
                .edit-form {
                    width: 650px;

                    >div {
                        position: relative;

                        .dismiss-edit {
                            position: absolute;
                            right: 0;
                            top: 5px;
                            margin: 0;

                        }
                    }

                    h4 {
                        width: auto;
                        font-family: "Merriweather Sans";
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 130%;
                        color: #242424;
                        border-bottom: 1px solid var(--bordercolor);
                        text-align: left;
                        margin: -30px -30px 30px -30px;
                        padding: 30px 29px;
                        float: none !important;
                    }
                }
            }
        }

        .rdw-list-wrapper {
            justify-content: flex-start;
            align-items: flex-start;
        }

        .rdw-option-wrapper {
            min-width: 27px;
            height: 27px;
            width: 27px;
            padding: 4px;
            margin: 0 2px;
            border: 1px solid var(--bordercolor);
            background-color: #f5f8ff;

            img {
                width: auto;
            }
        }

    }
}


.modalBox{
    &.vitals_add {
        animation: none;
        .modalMain{
            animation: none;
        }
    }
}