.footer {
    border-top: 1px solid var(--bordercolor);
    height: 45px;
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 25px;
    right: 25px;
    display: none;
    p {
        font-family: "Merriweather Sans";
        font-weight: 400;
        font-size: 12px;
        line-height: 40px;
        text-align: center;
        color: #67757c;
    }
}