@import '../style.scss';

.adminDashboard {
	background: #f3f4f7;
	position: relative;
	padding-left: 250px;
	height: 100vh;
	padding-top: 0;
	overflow: auto;
	transition: all 0.5s ease;
	@media (max-width: $NormalScreen) {
		padding-left: 210px;
	}
	.header-mainscreen {
		padding: 25px 25px 25px 25px;
		margin-top: 75px;
		min-height: calc(100vh - 75px);
		position: relative;
		@media (max-width: $NormalScreen) {
			padding: 15px 15px 15px 15px;
		}
		.AddNew {
			.modalBox {
				&.vitals-popup {
					.modalMain {
						animation: none;
					}
				}
			}
		}

		.submenu-tabination {
			margin: -25px -25px 25px -24px;
			background: #fff;
			display: flex;
			box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15);
			position: sticky;
			top: 75px;
			z-index: 6;
			padding-left: 25px;
			@media (max-width: $NormalScreen) {
				margin: -15px -15px 15px -15px;
			}
			li{
				margin:0 15px;
				@media (max-width: $NormalScreen) {
					margin:0 5px;
				}
				&:first-child{
					margin-left: 0;
				}
				a{
					font-size: 14px;
					color: var(--text);
					padding: 15px 0;
					display: block;
					position: relative;
					@media (max-width: $NormalScreen) {
						font-size: 12px;
					}
					&:after{
						content: "";
						position: absolute;
						bottom: 0;
						left: 0;
						opacity: 0;
						transition: .3s;
						right: 0;
						margin: auto;
						width: 10px;
						height: 3px;
						background: var(--blue);
					}
				}
				&:hover,
				&.active{
					a{
						color: var(--text-black);
						&:after{
							width: 100%;
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.filterSelect {
		max-width: 505px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		.select {
			max-width: 250px;
			width: 100%;
		}
	}

	.topMenu {
		margin: -30px -30px 30px -30px;
		background-color: #fff;
		position: relative;
	}


	&.leftHide {
		padding-left: 60px;
		transition: all 0.5s ease;
		.adminHeader{
			.logo-arrow {
				width: 70px;
				margin-left: -10px;
				@media (max-width: $NormalScreen) {
					margin-left: 0px;
				}
				.slide-in-arrow {
					transform: scaleX(-1);
					margin-top: 0;
				  }
				.big-logo{
					display: none;
				}
				.small-logo{
					display: block;
					max-width: 40px;
					@media (max-width: $NormalScreen) {
						max-width: 35px;
					}
				}
			}
		}
		.adminSidebar {
			left: 0;
			transition: all 0.5s ease;
			max-width: 70px;
			overflow: visible;
			z-index: 8;
		 }
		 .side_nav_item  {
			&:hover{
				background: rgba(62, 121, 247, 0.1);
				.submenu-sidebar{
					opacity: 1;
					pointer-events: auto;
				}
			}
		 }
		.menuList{
			overflow: visible;
			li {
				.menu-submenu-arrow{
					display: none;
				}
				.menuBox{
					padding: 12px 26px;
					p {
						opacity: 0;
						transition: all 0.5s ease;
						width: 0;
						height: 0;
						visibility: hidden;
						overflow: hidden;
					}
					span {
						font-size: 0;
					  }
				}
				.submenu-sidebar{
					position: absolute;
					left: 100%;
					top: 0;
					background-color: var(--white);
					padding: 0;
					min-width: 150px;
					max-height: unset;
					box-shadow: 9px 3px 10px 3px rgba(0, 0, 0, 0.09);
					opacity: 0;
					transition: all 0.1s ease;
					pointer-events: none;
					border-radius: 0 5px 5px 0;
					.menuBox {
						padding:8px 15px;
						span{
							font-size: 14px;
							padding: 0;
							@media (max-width: $NormalScreen) {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
}