.Users_Hospital-tab {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    .doctorsetting-data-titles {
        margin-bottom: 15px;

        h2 {
            font-weight: normal;
            color: var(--text);
            font-size: 24px;
        }
    }

    .treatment-table-block {
        .adminTable {
            padding: 0;
            border: none;
        }

        .adminTableHeader {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .tab-content {
            width: 100%;
        }
    }
}

a.edit-template {
    display: inline-block;
    margin-right: 10px;
}