@import '../../../style.scss';

// Template Header Design Css Start
.templates-list-view {
	.templates-header-block {
		.templates-header-part {
			.header-template {
				margin-bottom: 15px;
			}

			.header-row {
				border: 1px solid #ddd;
				padding: 10px;
			}
		}
	}

	.templates-footer-block {
		.templates-footer-part {
			.footer-template {
				margin-bottom: 15px;
			}

			.footer-row {
				border: 1px solid #ddd;
				padding: 10px;
			}
		}
	}
}

.header-template {
	.header {
		.header-row {
			max-width: 800px;
		}
	}
}

.footer-template {
	.footer {
		.footer-row {
			max-width: 800px;
		}
	}
}

div.single-tmp-header {
	padding-bottom: 0;
    h4 {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 36px;        
		color: rgba(0, 0, 0, 0.85);
    }

    p {
        margin-bottom: 5px;
        font-size: 18px;
		color: rgba(0, 0, 0, 0.85);
        font-weight: 400;
    }
	.header {
		.header-row {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 15px;
			border-bottom: 1px solid #ccc;
			padding-bottom: 15px;

			.header-left-block {
				margin-right: 15px;
				width: 100%;
				max-width: 400px;
				text-align: left;
				.header-logo {
					max-width: 200px;
					height: 110px;
					min-height: 1px;
					position: relative;
					text-align: left;
                    margin-bottom: 5px;
					img {
						max-height: 110px;
                        max-width: 250px;
					}
				}
			}

			.header-right-block {
				max-width: 550px;
                h4 {
                    text-align: right;
                }
            
                p {
                    text-align: right;
                }
			}
		}
	}

	&.tmp-header-2 {
		.header-center-block {
			width: 100%;
			text-align: center;

			.tmp-dr-name {
				position: relative;

				&::before {
					content: "";
					height: 1px;
					background-color: #214187;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
					right: 0;
					-webkit-print-color-adjust: exact;
				}

				h4 {
					display: inline-block;
					font-weight: bold;
					color: #081537;
					z-index: 1;
					position: relative;
					background-color: #fff;
					-webkit-print-color-adjust: exact;
					padding: 0 20px;
					margin: 0;
					span {
						color: #1F4187;
					}
				}
			}
		}
	}
}




.manage-setttings-prescriptions{
    div.header-footer-preview {
        h4 {
            font-size: 16px;
			@media (max-width: $NormalScreen){
				font-size: 14px;
			}
        }
    
        p {
            font-size: 12px;
			text-align: left;
			line-height: 140%;
			@media (max-width: $NormalScreen){
				font-size: 11px;
			}
        }
		.footer-preview{
			padding: 15px 15px;
		}
        .header-preview {
			padding: 15px 15px 0 15px;
            .header-left-block {
                max-width: 43%;
				text-align: left;
                .header-logo {
                    max-width: 130px;
                    height: 50px;
					img{
						max-height: 50px;
						max-width: 130px;
					}
                }
            }
    
            .header-right-block {
                max-width: 52%;
            }
        }
    }
}
