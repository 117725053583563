@import '../../../style.scss';


.Add-LabTest-modal {
    .modalBox {
        .modalMain {
            max-width: 98%;

            .modal-inner-scroll {
                min-height: calc(100vh - 150px);
                max-height: calc(100vh - 150px);
            }
        }
    }

    .language-table {
        .ant-table-body {
            height: calc(100vh - 263px);
            overflow: auto !important;
            h2{
                font-size: 16px;
                line-height: 120%;
            }
            @media (max-width: $NormalScreen) {
                height: calc(100vh - 240px);
            }
            .tableTitle {
                background: #f5f8ff;
                padding: 10px;
                border: 1px solid var(--bordercolor);
                margin-bottom: -1px;
            }
        }
    }

    .language-table table tbody td .action span.add-group {
        display: flex;
    }

    .language-table {
        .ant-table-row-expand-icon {
            width: 23px;
            height: 23px;
            &.ant-table-row-expand-icon-expanded{
                background-color: var(--blue);
                &:after,
                &:before{
                    background: var(--white);
                }
            }
            &:before {
                top: 10px;
                height: 2px;
                background: var(--blue);
            }

            &:after {
               
                left: 10px;
                width: 2px;
                background: var(--blue);
            }
        }

        .ant-table-expanded-row-fixed {
            padding: 25px 25px 25px 60px;
            margin: 0;
            overflow: visible !important;
            background: #f0f0f0;
            position: relative;

            // &:hover {
            //     z-index: 1;
            // }

            td.ant-table-cell {
                position: relative;

                &:first-child {
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 30px;
                        height: 6px;
                        bottom: 50%;
                        margin-left: -31px;
                        pointer-events: none;
                        border-bottom-left-radius: 8px;
                        border-left: 1px solid #c1c5c8;
                        border-bottom: 1px solid #c1c5c8;
                    }
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 0px;
                left: 30px;
                bottom: 53px;
                width: 1px;
                background: #c1c5c8;
            }

            .ant-table-wrapper {
                border: 1px solid var(--bordercolor);
                border-top: none;
            }

            .ant-table-body {
                height: auto;
                overflow: visible !important;
            }

            .ant-table-wrapper:only-child .ant-table {
                margin: 0;
            }
        }
    }
}