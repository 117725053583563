@import '../../style.scss';

.moduleAccesses,
.userroles{
    .access-icon{
        width: 26px;
        height: 26px;
        margin: 1px 3px;
        display: inline-block;
        vertical-align: top;
    }
}

.user-role-moduleaccess{
    .adminTable{
        border-radius: 0;
        padding: 0;
        border: none;
        .ant-table-wrapper{
            max-height: calc(100vh - 290px);
            overflow: auto;
        }
    }
    .ant-table-thead{
        > tr{
            > th{
                text-align: center;
                &:first-child{
                    text-align: left;
                }
            }
        }
    }
    .ant-table-tbody{
        > tr {
            > td{
                text-align: center;
                &:first-child{
                    text-align: left;
                }
                .formField{
                    margin: 0;
                    > label{
                        text-align: center;
                        display: inline-block;
                        margin: 0;
                        min-height: 17px;
                    }
                }
            }
        }
    }
}

