@import '../../style.scss';

.AptCustomForm {
    .ant-collapse {
        >.ant-collapse-item {
            >div.ant-collapse-header {
                border-bottom: 1px solid #D6E2FC;
                background: #F3F8FF;
            }

            .ant-collapse-header-text {
                display: flex;

                >span {
                    width: calc(40% + 75px);

                    &.actions {
                        width: 150px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .ant-collapse-content {
        >.ant-collapse-content-box {
            padding: 0;
        }
    }

    .ant-collapse-item {
        &.ant-collapse-item-active {
            background: transparent;
        }
    }


}