.OnBoarding{
    min-height: calc(100vh - 40px);
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
    background-color: #f2f6ff;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url("../../assets/images/Admin-Hero.jpg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        opacity: 0.07;
        z-index: 0;
    }
    .login-logo{
        max-width: 180px;
        width: 100%;
        margin-bottom: 40px;
    }
    .bottomImg {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 0;
        display: none;
    }
    .setPasswordBox {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: -20px;
    }
    .whiteBox{
        padding: 50px;
        width: 600px;
        min-height: 328px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 3px 33px rgba(70, 119, 240, 0.05);
        position: relative;
        z-index: 1;
        .box-heading{
            text-align: center;
            margin-bottom: 40px;
            h3{
                font-size: 32px;
                color: #31507d;
                margin: 0 0 20px 0;
                font-weight: 400;
            }
            p{
                font-size: 16px;
                color: #a5b2c6;
                font-weight: 300;
            }
        }
        .password-action-field,
        .email-action-field{
            position: relative;
            &::before{
                content: "";
                position: absolute;
                z-index: 1;
                background-repeat: no-repeat;
                background-position: 0 0;
                height: 25px;
                width: 25px;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 20px;
            }
            input{
                padding-left: 65px;
                height: 55px;
            }
        }
        .password-action-field{
            &::before{
                background-image: url(../../assets/images/lock.svg);
            }
        }
        .email-action-field{
            &::before{
                background-image: url(../../assets/images/Mail.svg);
            }
        }
        .button {
            width: 100%;
            button{
                width: 100%;
            }
        }
    }
    .bottomFooter{
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
    .remove-label{
        label{
            display: none;
        }
    }

}

.password-action-field {
	position: relative;

	.passwordeyeicon {
		position: absolute;
		top: 16px;
        right: 14px;
		cursor: pointer;
		color: #A6A6A6;
		width: 22px;
		height: 22px;
		background: url("../../assets/images/eye-hide-line.svg") no-repeat center center;
		background-size: 100% auto;
		&.hidePassword{
            background: url("../../assets/images/Show.svg") no-repeat center center;
            background-size: 100% auto;
        }
        > i {
            display: none;
        }
	}
}