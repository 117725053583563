@import '../../style.scss';

.tableTitle{
    h2{
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        text-align: left;
        color: #242424;
        @media (max-width: $NormalScreen) {
            font-size: 16px;
        }
        .info-icon{
            margin-left: 10px;
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: top;
            svg{
                width: 100%;
                height: 100%;
            }
        }
    }
}