.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



:root {
  --lightgrey: #f6f6f6;
  --mainbg: #f6f8fc;
  --grey: #eaeaea;
  --white: #fff;
  --blue: #4677f0;
  --menu: #67757c;
  --text: #67757c;
  --text-black: #000;
  --green: #17c403;
  --selectbg: #f7f7f7;
  --bordercolor: #dbe0e4;
}

*::after,
*::before,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  /* For some Androids */
}

* {
  outline: none !important;
}

body a {
  outline: none;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0 0 15px;
  font-family: "Merriweather Sans", sans-serif;
}

body h1:last-child,
body h2:last-child,
body h3:last-child,
body h4:last-child,
body h5:last-child,
body h6:last-child {
  margin-bottom: 0;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

body p {
  font-size: 15px;
  line-height: 140%;
  margin: 0 0 15px;
  padding: 0;
  font-family: "Merriweather Sans", sans-serif;
}

body p span.errField {
  display: block;
  margin-top: -10px;
  font-size: 12px;
  color: #7c0000;
}

body p:empty {
  margin: 0;
  line-height: 0;
}

body p:last-child {
  margin-bottom: 0;
}

p strong {
  font-weight: bold;
}

a,
div a:hover,
div a:active,
div a:focus,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

a,
span,
div a:hover,
div a:active,
button {
  text-decoration: none;
}

div ul,
div ul li,
div ol,
li {
  list-style: none;
  font-family: "Merriweather Sans", sans-serif;
  margin: 0;
  padding: 0;
}

div input,
div select,
div textarea,
div button {
  font-family: "Merriweather Sans", sans-serif;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 #fbfbfb;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.react-datepicker__time-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: #fbfbfb;
}

*::-webkit-scrollbar-thumb {
  background: #c4c4c4;
}


.main-wrapper {
  background: #f4f5f7;
}

textarea::placeholder,
input::placeholder {
  opacity: 0.5;
}

input:-ms-input-placeholder {
  opacity: 0.5;
}

input::-ms-input-placeholder {
  opacity: 0.5;
}

.no-label .formField>label {
  display: none;
}

.no-templtes {
  padding: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}


/*table*/
.adminTable.full-height .ant-skeleton {
  min-height: 71vh;
}

.adminTable .ant-skeleton {
  min-height: 55vh;
}

.submenu-available .adminTable .ant-skeleton {
  min-height: 49vh;
}

.submenu-available .adminTable.increaseheight .ant-skeleton,
.adminTable.increaseheight .ant-skeleton {
  min-height: 64vh;
}

.adminTable.increaseheight {
  min-height: calc(100vh - 177px);
}

.adminTable {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  overflow: hidden;
  border: 1px solid var(--bordercolor);
}

.adminTable .table {
  width: 100%;
  background: var(--white);
  margin-bottom: 0;
}

.adminTable .table table td,
.adminTable .table table th {
  font-family: "Merriweather Sans";
  font-size: 14px;
  line-height: 20px;
  padding: 10px 12px;
  position: relative;
  vertical-align: middle;
  font-weight: 300;
}

.adminTable .ant-table-tbody>tr>td {
  border-top: none;
}

.adminTable .table table td .link {
  color: var(--blue);
  text-decoration: underline;
}

.adminTable .table table td .link:hover {
  color: rgba(0, 0, 0, 0.85);
}


.adminTable .table table th::before {
  display: none;
}

.adminTable .table table td p,
.adminTable .table table th p {
  font-size: inherit;
  margin-bottom: 8px;
}

.adminTable .table table td p:last-child,
.adminTable .table table th p:last-child {
  margin-bottom: 0;
}

.adminTable .table table thead {
  /* position: sticky;
  top: 0;
  z-index: 3; */
  font-size: 15px;
}

.adminTable .table table thead th {
  border-right: none;
  font-weight: 500;
  background: rgb(245, 248, 255);
  border-top: 1px solid #e4e8eb;
  border-bottom: 1px solid #e4e8eb;
  letter-spacing: 0.3px;
}

.adminTable .adminTableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  position: relative;
  z-index: 4;
}

.adminTableHeader .left-adminHeader {
  display: flex;
  flex-wrap: wrap;
}

.adminTableHeader .left-adminHeader>div {
  margin-right: 10px;
}

.adminTableHeader .left-adminHeader>div:last-child {
  margin-right: 0px;
}

.adminTableHeader.no-space {
  padding: 0;
}

.adminTableHeader.borders {
  border: 1px solid #F0F2F5;
}

.adminTable .table-header-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adminTableHeader.no-space .left-adminHeader>div {
  margin: 0;
}

.adminTableHeader .left-adminHeader div.react-select__control {
  min-height: 58px;
  border: 1px solid #F0F2F5 !important;
  border-radius: 0px;
  padding: 8px 40px 8px 16px;
  box-shadow: none;
  margin-left: -1px;
  margin-top: -1px;
  background: #FCFCFC;
}

.adminTableHeader.borders+.table {
  margin-top: -1px;
}

.adminTableHeader .left-adminHeader div.react-select__control .react-select__value-container,
.adminTableHeader .left-adminHeader div.react-select__control .react-select__dropdown-indicator {
  padding: 0;
}

.adminTableHeader .left-adminHeader div.react-select__control .react-select__value-container {
  min-width: 80px;
}

.adminTableHeader .left-adminHeader div.react-select__control .react-select__dropdown-indicator svg path {
  fill: var(--blue);
}

.adminTableHeader .left-adminHeader div.react-select__control .react-select__single-value {
  color: #231F20;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.28px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adminTableHeader .left-adminHeader div.react-select__control .react-select__indicators {
  position: absolute;
  top: 0;
  right: 16px;
  height: 20px;
  width: 20px;
  margin: auto;
  bottom: 0;
}

.adminTable .table-header-search .formField {
  margin-bottom: 0;
}

.adminTable .table-header-search>* {
  margin-left: 20px;
}

.adminTable .table-header-search>*:first-child {
  margin-left: 0;
}

.adminTable .table-header-search>*:empty {
  display: none;
}

.adminTable .ant-pagination {
  padding: 20px 20px 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #f0f0f0;
}

.adminTable .ant-pagination-item {
  border-radius: 3px;
}

.adminTable .ant-pagination-item:hover,
.adminTable .ant-pagination-item-active {
  background: var(--blue);
  border-color: var(--blue);
}

.adminTable .ant-pagination-item:hover>a,
.adminTable .ant-pagination-item-active a {
  color: var(--white);
}

.adminTable .ant-pagination-prev:hover .ant-pagination-item-link,
.adminTable .ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--white);
  border-color: var(--blue);
  background-color: var(--blue);
}

.adminTable .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--blue);
}

.adminTable .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--blue);
  color: var(--white);
}

.adminTable .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--blue);
  box-shadow: none;
  border-right-width: none;
  outline: 0;
}

.adminTable .ant-table-tbody>tr.ant-table-row:hover>td,
.adminTable .ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: rgb(245, 248, 255);
}

.adminTable .ant-tag {
  margin: 3px;
}

.table-search .formField>input {
  background: url("./assets/images/search.svg") no-repeat 10px center;
  padding-left: 35px;
  width: 350px;
}

.ant-table-tbody .action {
  display: flex;
}

.ant-table-tbody .action>span {
  cursor: pointer;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.ant-table-tbody .action>span>img {
  filter: grayscale(1);
  max-width: 26px;
  max-height: 26px;
  transition: all 0.3s ease;
  opacity: 0.8;
}

.ant-table-tbody .action>span:hover>img {
  filter: none;
  opacity: 1;
  transform: scale(1.05);
}

.ant-table-row:hover {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
}

.ant-table-row:hover .action>span>img {
  filter: none;
  opacity: 1;
  transform: scale(1.05);
}

.adminTable .table table td>img,
.adminTable .table table td .payment-icon {
  max-width: 70px;
  display: block;
}

.adminTable .table table td .payment-icon img {
  max-height: 45px;
}

.adminTable .table table td .formField {
  margin-bottom: 0;
}

.adminTable .ant-table-body {
  min-height: 150px;
}

.adminTable.no-border-padding {
  padding: 0;
  border: none;
}

.ant-table table tr.new-error td {
  background: rgba(255, 0, 0, 0.04);
}

/*tabel end*/

/*react dropdown*/
.big-dropdown div.react-select__control {
  min-width: 250px;
}

div.react-select__control {
  border: 1px solid var(--bordercolor);
  min-height: 42px;
  min-width: 105px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 14px;
  color: var(--text-black);
  cursor: pointer;
}

div.react-select__control:hover {
  border: 1px solid var(--blue);
  min-height: 42px;
  box-shadow: none;
}

span.react-select__indicator-separator {
  display: none;
}

div.react-select__menu {
  margin: 0;
  min-width: 120px;
}

div.react-select__menu li,
div.react-select__menu div {
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  line-height: 120%;
}

div.react-select__menu div.react-select__option--is-selected {
  background-color: #DEEBFF;
}

div.react-select__menu>div {
  padding: 0;
  max-height: 130px;
  overflow: auto;
}

.table-header-search div.react-select__menu>div {
  max-height: 260px;
}

/*react dropdown end*/


div.finance-date-rangebox {
  border: 1px solid var(--bordercolor);
  height: 42px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 14px;
  color: var(--text-black);
  position: relative;
}

div.finance-date-rangebox:hover {
  border: 1px solid var(--blue);
  height: 42px;
  box-shadow: none;
}

div.finance-date-rangebox .rangebox-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

div.finance-date-rangebox>p {
  margin-left: 5px;
  font-size: 14px;
}

div.finance-date-rangebox>p b {
  margin: 0 5px;
}

div.finance-date-rangebox>p:empty {
  display: none;
}

span.rangebox-btn img {
  margin-right: 10px;
}

span.rangebox-btn.active::after {
  content: ":";
  margin-left: 5px;
}



/* div.finance-date-rangebox > p::before{
  content: ":";
  margin-right: 5px;
} */


/*ant-check box table*/

span.ant-checkbox-inner {
  border: none;
  background: transparent;
}

span.ant-checkbox-checked::after,
span.ant-checkbox-inner::after {
  display: none !important;
}

span.ant-checkbox-inner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  border: 1px solid var(--bordercolor);
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}

span.ant-checkbox-inner:hover:before {
  border-color: var(--blue);
}

span.ant-checkbox-checked span.ant-checkbox-inner::before {
  background-color: var(--blue);
  background-image: url("./assets/images/check-tick.png");
  border-color: var(--blue);
}

span.ant-checkbox-checked.ant-checkbox-disabled span.ant-checkbox-inner::before {
  background-color: #CFCFCF;
  border-color: #CFCFCF;
}

/*ant-check box table end*/


/*tooltip*/
div.ant-tooltip .ant-tooltip-inner {
  min-width: 25px;
  min-height: 20px;
  padding: 5px 12px;
  font-size: 12px;
  border-radius: 5px;
}

div.ant-tooltip.ant-tooltip-placement-top,
div.ant-tooltip.ant-tooltip-placement-topLeft,
div.ant-tooltip.ant-tooltip-placement-topRight {
  padding-bottom: 5px;
}

div.ant-tooltip.ant-tooltip-placement-right,
div.ant-tooltip.ant-tooltip-placement-rightTop,
div.ant-tooltip.ant-tooltip-placement-rightBottom {
  padding-left: 5px;
}

div.ant-tooltip.ant-tooltip-placement-bottom,
div.ant-tooltip.ant-tooltip-placement-bottomLeft,
div.ant-tooltip.ant-tooltip-placement-bottomRight {
  padding-top: 5px;
}

div.ant-tooltip.ant-tooltip-placement-left,
div.ant-tooltip.ant-tooltip-placement-leftTop,
div.ant-tooltip.ant-tooltip-placement-leftBottom {
  padding-right: 5px;
}

/*tooltip end*/

/*loader*/
.r-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: #fff
}

.r-loader:not(.main-loader-done) {
  opacity: 0;
  z-index: -1;
  transition: all .5s ease;
  pointer-events: none
}

.r-loader .spinner-box {
  width: 430px;
  height: 430px;
  display: flex;
  justify-content: center;
  align-items: center
}

.r-loader .spinner-box .circle-border {
  width: 300px;
  height: 300px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(0deg, transparent 31%, #062e83 49%, #4566ad 50%);
  -webkit-animation: spin .9s linear infinite !important;
  animation: spin .9s linear infinite !important
}

.r-loader .spinner-box .circle-core {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 50%
}

.r-loader .spinner-box em {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto
}

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: #fff
}

.page-loader:not(.page-loader-done) {
  opacity: 0;
  z-index: -1;
  transition: all .5s ease;
  pointer-events: none
}

.page-loader .spinner {
  margin: auto;
  border: 3px solid #e2eaff;
  width: 50px;
  height: 50px;
  display: inline-block;
  position: absolute;
  top: 45%;
  border-radius: 50%;
  border-right: 3px solid var(--blue);
  text-align: center;
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: .9s;
  animation-duration: .9s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(.53, .21, .29, .67);
  animation-timing-function: cubic-bezier(.53, .21, .29, .67)
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn)
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

/*loader end*/

/*Add Language modal*/
.delete-lang {
  position: relative;
}

.delete-lang .remove-lang {
  position: absolute;
  right: 9px;
  bottom: 10px;
  cursor: pointer;
}

.delete-lang .remove-lang img {
  filter: grayscale(1);
  max-width: 24px;
  max-height: 24px;
  transition: all 0.3s ease;
  opacity: 0.8;
}

.delete-lang .remove-lang:hover>img {
  filter: none;
  opacity: 1;
}

.delete-lang .formField input.formControl {
  padding-right: 40px;
}

/*Add Language modal end*/


/*calender*/
div.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 0;
  margin-top: 0;
}

div.react-datepicker {
  border-radius: 3px;
  background: #fff;
  border: 0 solid transparent;
  box-shadow: 6px 7px 20px rgba(0, 0, 0, .07058823529411765);
  padding: 0px;
}

.react-datepicker .react-datepicker__navigation {
  left: 0;
  top: 10px;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #4677f0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.react-datepicker button.react-datepicker__navigation {
  right: 10px;
  left: auto;
}

.react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous {
  right: auto;
  left: 10px;
}

.react-datepicker .react-datepicker__navigation span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: auto;
  right: auto;
  font-size: 0;
  background: url("assets/images/blue-arrow-down.svg") no-repeat 50%;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 8px;
  height: 4px;
  margin-top: -1px;
  margin-left: -1px;
}

.react-datepicker .react-datepicker__navigation span::before {
  display: none;
}

.react-datepicker .react-datepicker__navigation span.react-datepicker__navigation-icon--next {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin: -1px -5px 0 0;
}

div.react-datepicker__header {
  background-color: rgb(235, 238, 247);
  border-radius: 0;
  border-bottom: 0;
  padding: 10px 0 !important;
}

div.react-datepicker__header>div.react-datepicker__current-month {
  display: none;
}

div.react-datepicker__header>div.react-datepicker__header__dropdown {
  display: flex;
  justify-content: center;
  width: 170px;
  flex-wrap: wrap;
  margin: 0 auto;
}

div.react-datepicker__header .react-datepicker__header__dropdown>div {
  max-width: 80px;
  width: 100%;
  text-align: left;
  margin: 0 10px 0 0;
  position: relative;
}

.react-datepicker__header .react-datepicker__header__dropdown>div:last-child {
  margin-right: 0;
  max-width: 55px;
}

.react-datepicker__header .react-datepicker__header__dropdown>div div.react-datepicker__month-read-view,
.react-datepicker__header .react-datepicker__header__dropdown>div div.react-datepicker__year-read-view {
  visibility: visible !important;
  border: none;
}

.react-datepicker__header .react-datepicker__month-read-view--down-arrow,
.react-datepicker__header .react-datepicker__year-read-view--down-arrow {
  position: absolute;
  top: 10px;
  right: 0;
  background: url(assets/images/blue-arrow-down.svg) no-repeat 0 0;
  -webkit-transform: none;
  transform: none;
  border: none;
  width: 10px;
  height: 6px;
}

.react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-read-view--selected-month,
.react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-read-view--selected-year {
  width: 100%;
  border-bottom: none;
  display: block;
  font-family: "Merriweather Sans";
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #4677f0;
  padding: 4px 12px 4px 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

div.react-datepicker__day,
div.react-datepicker__day-name,
div.react-datepicker__time-name {
  width: 1.6rem;
  height: 1.6rem;
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  color: var(--blue);
}

div.react-datepicker__month .react-datepicker__week>div {
  background: #fff;
  border: none;
  border-radius: 50px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #000;
  opacity: 1;
}

div.react-datepicker__month .react-datepicker__week>div:hover {
  color: var(--blue);
}

div.react-datepicker__month .react-datepicker__week>div.react-datepicker__day--outside-month {
  opacity: 0.4;
}

div.react-datepicker__month .react-datepicker__week>div.react-datepicker__day--selected,
div.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #4677f0;
  background-color: var(--blue);
  color: #fff !important;
  box-shadow: 2px 0 6px rgba(0, 0, 0, .16);
}

div.react-datepicker__header .react-datepicker__month-dropdown,
div.react-datepicker__header .react-datepicker__month-year-dropdown,
div.react-datepicker__header .react-datepicker__year-dropdown {
  position: absolute;
  width: auto;
  left: 0;
  top: 100%;
  z-index: 1;
  text-align: center;
  border-radius: 0;
  right: -50px;
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #242424;
  background: #fff;
  box-shadow: 3px 3px 24px rgba(0, 0, 0, .15);
  border: none;
  max-height: 190px;
  overflow: auto;
  width: 85px;
}

div.react-datepicker__header .react-datepicker__month-dropdown>div,
div.react-datepicker__header .react-datepicker__month-year-dropdown>div,
div.react-datepicker__header .react-datepicker__year-dropdown>div {
  padding: 8px 5px;
  line-height: 100%;
  text-align: left;
}

div.react-datepicker__header .react-datepicker__month-dropdown>div:hover,
div.react-datepicker__header .react-datepicker__month-dropdown>div[aria-selected="true"],
div.react-datepicker__header .react-datepicker__month-year-dropdown>div:hover,
div.react-datepicker__header .react-datepicker__month-year-dropdown>div[aria-selected="true"],
div.react-datepicker__header .react-datepicker__year-dropdown>div:hover,
div.react-datepicker__header .react-datepicker__year-dropdown>div[aria-selected="true"] {
  color: #fff;
  background: #4677f0;
  -webkit-filter: drop-shadow(2px 0 6px rgba(0, 0, 0, .16));
  filter: drop-shadow(2px 0 6px rgba(0, 0, 0, .16));
}

div.react-datepicker__header .react-datepicker__month-dropdown>div>span,
div.react-datepicker__header .react-datepicker__month-year-dropdown>div>span,
div.react-datepicker__header .react-datepicker__year-dropdown>div:first-child,
div.react-datepicker__header .react-datepicker__year-dropdown>div:last-child,
div.react-datepicker__header .react-datepicker__year-dropdown>div>span {
  display: none;
}

/*calender end*/
div.react-select__multi-value {
  background-color: rgb(245, 248, 255);
}

/*Accordian*/
div.ant-collapse {
  background-color: var(--white);
  border: none;
  border-bottom: 1px solid var(--bordercolor);
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000;
}

.ant-collapse>.ant-collapse-item>div.ant-collapse-header {
  position: relative;
  padding-right: 40px;
  border-radius: 0;
}

.ant-collapse>div.ant-collapse-item {
  border-bottom: none;
}

div.ant-collapse-header .ant-collapse-expand-icon {
  position: absolute;
  right: 15px;
  top: 16px;
  background: url("assets/images/blue-arrow-down.svg") no-repeat center center;
  background-size: 100% auto;
  width: 12px;
  height: 12px;
}

div.ant-collapse-header .ant-collapse-expand-icon .ant-collapse-arrow {
  display: none !important;
}

.ant-collapse-item.ant-collapse-item-active {
  background-color: #F7F7F7;
}

div.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent;
  border-top: 1px solid var(--bordercolor);
}

/*Accordian end*/


/*time picker*/
div.ant-picker {
  width: 125px;
  height: 40px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}

div.ant-picker .ant-picker-input>input {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--text-black);
}

div.ant-picker .anticon svg path,
div.ant-picker .anticon svg {
  fill: var(--blue);
}

/*time picker end*/


/*min-height for screen and sticky button*/
.min-height {
  min-height: calc(100vh - 380px);
  max-height: calc(100vh - 380px);
  overflow: auto;
}

.adminTable .activeButton.right {
  padding-top: 20px;
  border-top: 1px solid var(--bordercolor);
  text-align: right;
}

/*min-height for screen and sticky button end*/

.inline-flex {
  display: inline-flex;
  align-items: center;
}

.select-data {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #777;
  background: #fff;
  padding: 50px;
  margin: 0;
  border: 1px solid var(--bordercolor);
}

.select-data span {
  display: block;
  color: #000;
  font-size: 18px;
  margin-bottom: 10px;
}

.select-data b {
  color: #000;
  margin: 0 2px;
}

.select-data span.images {
  display: block;
  margin-bottom: 20px;
}

.table-header-dropDown.d-flex {
  align-items: center;
}

.table-header-dropDown.d-flex>label {
  margin: 0 10px 0 0;
  font-weight: 400;
  color: #455560;
  font-size: 14px;
  font-family: "Merriweather Sans";
}


.react-form-builder .react-form-builder-preview .edit-form div.dynamic-option-list ul li input.form-control {
  min-width: 100%;
  width: 100%;
  margin: 0;
}

div.ant-dropdown {
  background: #fff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  overflow: hidden;
}

div.ant-dropdown .action p {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 400;
  line-height: 157.143%;
  padding: 8px 12px;
  margin-bottom: 0;
  cursor: pointer;
}

div.ant-dropdown .action p:hover {
  background-color: var(--blue);
  color: #fff;
}


@media only screen and (max-width: 1600px) {
  .adminTable {
    padding: 15px;
  }

  .adminTable .table table td,
  .adminTable .table table th {
    font-size: 13px;
    padding: 12px;
  }

  .ant-table-tbody .action>span {
    width: 22px;
    height: 22px;
  }

  .ant-table-tbody .action>span>img {
    max-width: 22px;
    max-height: 22px;
  }

  .adminTable .activeButton.right {
    padding-top: 15px;
  }

  .min-height {
    min-height: calc(100vh - 350px);
    max-height: calc(100vh - 350px);
  }
}

.error-log-modal {
  border-radius: 12px;
  overflow: hidden;
}

.error-log-modal .ant-modal-header div {
  font-size: 23px;
  font-weight: bold;
}

.error-log-modal .ant-modal-body {
  padding-left: 40px;
}

.error-log-modal .ant-modal-body .modal-details {
  font-size: 15px;
}

.error-log-modal .ant-modal-body .modal-details div {
  margin-bottom: 5px;
}

.error-log-modal .ant-modal-footer .modal-footer-button .modal-left-right-btn button {
  border-radius: 5px;
  padding: 4px 20px;
  margin-right: 10px;

}



/*print*/
@page {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 2cm;
  margin-right: 2cm;
}

@media print {
  .no-print {
    display: none !important;
  }

  body {
    background-color: #fff;
    -webkit-print-color-adjust: exact;
  }

  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

/*error print*/
@media print {
  .modal-details {
    font-size: 18px;
  }
}