.checkbox{
    position: relative;
    input[type="checkbox"]{
        position:absolute;
        opacity: 0;
        margin: 0;
        z-index: -1;
        height: auto;
        width: auto;
        &:checked{
            + label{
                &:before{
                    background-color: var(--blue);
                    background-image: url(img/check-tick.png);
                    border-color: var(--blue);
             }  
            }
        }
    }
    > label{
        position: relative;
        cursor: pointer;
        padding-left: 25px;
        &:before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            left: 0;
            display: block;
            width: 16px;
            height: 16px;
            background-color: #fff;
            background-position: center center;
            background-repeat: no-repeat;
            border: 1px solid var(--bordercolor);
            border-radius: 2px;
            border-collapse: separate;
            transition: all 0.3s;
        }
        
    }
    
}