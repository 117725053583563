@import '../../style.scss';


.multi-lang-add-popup{
    .modalBox{
        .modalMain{
            max-width: 80%;
            width: 100%;
            @media (max-width: $NormalScreen) {
                max-width: 97%;
            }
            .modal-inner-scroll{
                min-height: calc(100vh - 150px);
                max-height: calc(100vh - 150px);
            }
        }
    }
    &.update-modal{
        .modalBox{
            .modalMain{
                .modal-inner-scroll{
                    min-height: unset;
                }
            }
        }
    }
}

.language-table{
    table{
        table-layout: fixed;
        width: 100%;
        text-align: left;
        border-radius: 2px 2px 0 0;
        border-collapse: separate;
        border-spacing: 0;
        th,
        td{
            font-family: "Merriweather Sans";
            font-size: 14px;
            line-height: 20px;
            padding: 15px;
            position: relative;
            vertical-align: middle;
            font-weight: 300;
            @media (max-width: $NormalScreen) {
                font-size: 13px;
                padding: 12px;
            }
        }
        thead{
            th{
                border-right: none;
                font-weight: 500;
                background: rgb(245, 248, 255);
                border-top: 1px solid #e4e8eb;
                border-bottom: 1px solid #e4e8eb;
                letter-spacing: 0.3px;
                &.action{
                    width: 120px;
                }
            }
        }
        tbody{
            tr{
                &:last-child{
                    td{
                        .action {
                            span{
                                &.add-group{
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
            td{
                border-bottom: 1px solid #f0f0f0;               
                border-left: 1px solid #f0f0f0;
                border-right: 1px solid #f0f0f0;
                padding: 0;
                div.react-select__control{
                    border: none;
                    border-radius: 0;
                    box-shadow: none !important;
                    .react-select__value-container{
                        padding: 2px 13px;
                    }
                    &.react-select__control--is-focused{
                        background: #f4f4f4;
                        border: none;
                    }
                }
                .formField{
                    margin: 0;
                    div.react-select__control,
                    input{
                        border: none;
                        border-radius: 0;
                        padding: 0 15px;
                    }
                    input{
                        padding: 0 15px;
                    }
                    &.active{
                        input{
                            background: #f4f4f4;
                        }
                    }
                }
                .action {
                    display: flex;
                    padding: 0 21px;
                    align-items: center;
                    justify-content: flex-end;
                    span{
                        cursor: pointer;
                        width: 26px;
                        height: 26px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 5px;
                        img{
                            filter: grayscale(1);
                            max-width: 26px;
                            max-height: 26px;
                            transition: all 0.3s ease;
                            opacity: 0.8;
                        }
                        &.add-group{
                            display: none;
                        }
                    }
                    &:hover{
                        span{
                            img{
                                filter: none;
                                opacity: 1;
                                transform: scale(1.05);
                            }
                        }
                    }
                }
            }
        }
    }  
}