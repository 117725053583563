@import '../../style.scss';


.whatsnew{
    .whatsnew-img {
        width: 80px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        border: 1px solid var(--bordercolor);
        min-height: 55px;
        overflow: hidden;
        border-radius: 5px;
        background-color: #f4f4f4;
        img{
            height: 55px;
            width: 100%;
            background-color: #f4f4f4;
            object-fit: cover;
        }
      }
}

.whats-new-modal{
    .modalBox{
        .modalMain {
            max-width: 1000px;
            width: 100%;
            .modal-inner-scroll {
                min-height: 350px;
              }
          }
    }
    div.react-select__multi-value{
        background-color: rgb(245, 248, 255);
    }
    .twoColsField{
        .formField{
            &:first-child{
                width: 80%;
            }
            &:last-child{
                width: 18%;
            }
        }
    }
    .upload-img-show{
        display: flex;
        flex-wrap: wrap;
        > span{
            position: relative;
            width: 23.43%;
            background: var(--bordercolor);
            margin-right: 2%;
            border-radius: 5px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;
            max-height: 130px;
            &:nth-child(4n){
                margin-right: 0;
            }
        }
        .delete{
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            justify-content: center;
            align-items: center;
            display: flex;
            background: #fff;
            border-radius: 5px;
            padding: 8px;
            cursor: pointer;
            box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
            &:hover{
                background: #f00;
                img{
                    filter: brightness(0) invert(1);
                }
            }
        }
    }
}


