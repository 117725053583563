.UsersRole {
    .Members {
        width: 40%;
    }
}

.white-box {
    &.usersRole {
        min-width: 1046px;
        margin: 10px auto 0 auto;
        max-width: 1046px;
    }
}

.member-remove {
    p {
        height: 34px;
        background: #fff;
        border: 1px solid #707070;
        position: relative;
        border-radius: 50px;
        display: inline-block;
        vertical-align: top;
        margin: 0 10px 10px 0;
        padding: 6px 42px 6px 15px;
        font-size: 15px !important;

        span {
            position: absolute;
            top: 8px;
            right: 15px;
            cursor: pointer;
            transition: all 0.5s ease;

            &:hover {
                filter: invert(1);
                transition: all 0.5s ease;
                opacity: 0.5;
            }
        }
    }
}

.Department-Table {
    .adminTableHeader {
        z-index: 0;
        padding-bottom: 0;
    }
}

.departmentsSettings {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    >.action-field.right {
        width: 150px;
    }

    .departmentsoption {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: calc(100% - 150px);

        p {
            margin: 0 40px 0 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        >div {
            display: flex;
            flex-wrap: wrap;

            .formField {
                margin: 0 15px 0 0;
            }
        }

        .tooltip {
            margin-left: 30px;
        }
    }

    .header-setting {
        width: 100%;
        margin-top: 20px;

        .formField {
            max-width: 390px;
            width: 100%;

            .sign-prev {
                position: absolute;
                bottom: 1px;
                left: 1px;
                right: 1px;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: none;
                height: 89px;
                line-height: 0;

                img {
                    width: auto;
                    max-height: 91px;
                    height: 100%;
                    margin: 0;
                }
            }
            .delete{
                position: absolute;
                top: 29px;
                right: 1px;
                width: 30px;
                height: 30px;
                background: #f55;
                padding: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;
                &:hover{
                    background-color: #f00;
                }
                img{
                    filter: invert(1) brightness(1000);
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                }
            }
        }
    }
}