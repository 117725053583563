@import '../../../style.scss';

.manage-setttings-prescriptions {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .manage-settings-main-menus{
        max-width: 250px;
        width: 100%;
        li{
            position: relative;
            &:hover,
            &.active{
                &::before{
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    background: #4677F0;
                    border-radius: 2px 0px 0px 2px;
                    width: 4px;
                    height: 22px;
                    z-index: 1;
                    right: 0;
                    margin: auto;
                    overflow: hidden;
                }
            }
            .Drapicon{
                position: absolute;
                top: 0;
                right: 15px;
                bottom: 0;
                height: 20px;
                width: 10px;
                margin: auto;
            }
        }
    }
    .manage-settings-submenus{
        max-width: calc(100% - 260px);
        margin-left: 10px;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        padding: 35px 35px 50px 35px;
        position: relative;
        @media (max-width: $NormalScreen) {
            padding: 25px;
        }
    }

    .manage-settings-submenus{
        .d-flex{
            margin: 0 -35px;
            flex-wrap: wrap;
            @media (max-width: $NormalScreen) {
                margin: 0 -15px;
            }
            > div{
                padding: 0 35px;
                width: 25%;
                &.formField.checkbox{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 4;
                    right: 0;
                    width: auto;
                }
                @media (max-width: $NormalScreen) {
                    padding: 0 15px;
                    width: 25%;
                }
            }
        }
    }
}

.manage-setttings-prescriptions-wrap{
    .activeButton.right{
        text-align: right;
    }
}