@import '../../style.scss';

.finance-white-board {
    width: 100%;
    &.main-loader{
        .finance-table-block:before{
            pointer-events: none;
            z-index: 1;
            opacity: 1;
            visibility: visible;
        }
    }
    .finance-header-filter {
        .finance-filter-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .finance-filter-nav {
                display: flex;
                border-radius: 3px;

                li {
                    font-size: 18px;
                    padding: 11px 22px;
                    cursor: pointer;
                    border: 1px solid #D9D9D9;
                    transition: all 0.5s ease 0s;
                    color: #67757c;
                    font-weight: normal;

                    @media (max-width: $NormalScreen) {
                        font-size: 16px;
                        padding: 8px 15px;
                    }

                    &:hover,
                    &.active {
                        color: #4677f0;
                        border-color: #4677f0;
                        background-color: rgba(70, 119, 240, 0.1);
                    }

                    &:last-child {
                        border-radius: 0 3px 3px 0;
                    }

                    &:first-child {
                        border-radius: 3px 0px 0 3px;
                    }
                }
            }

            .finance-date-rangebox {
                display: flex;
                align-items: center;

                .rdrDateRangePickerWrapper {
                    width: 100%;

                    .rdrCalendarWrapper {
                        width: calc(100% - 226px);
                    }
                }
                p {
                    font-size: 18px;
                    color: #242424;
                    margin-left: 11px;

                    @media (max-width: $NormalScreen) {
                        font-size: 14px;
                    }

                    b {
                        color: #67757c;
                    }
                }
            }
        }
    }

    .finance-all-status-block {
        padding-bottom: 40px;

        @media (max-width: $NormalScreen) {
            padding-bottom: 30px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                padding: 25px;
                background-color: #E2EAFF;
                min-width: 355px;
                margin-right: 30px;

                @media (max-width: $NormalScreen) {
                    margin-right: 15px;
                    padding: 15px;
                    background-color: #E2EAFF;
                    min-width: 240px;
                }

                &:last-child {
                    margin-right: 0px;
                }

                &:first-child {
                    .info-icon-tooltip {
                        .info-tooltip-data {
                            min-width: 210px;
                        }
                    }
                }

                .status-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    h4 {
                        font-size: 18px;
                        color: #242424;
                        margin-bottom: 0;

                        @media (max-width: $NormalScreen) {
                            font-size: 14px;
                        }
                    }

                    .info-icon {
                        line-height: 0;
                        width: 20px;
                        height: 20px;
                        background: transparent;
                        border: 2px solid #4677f0;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        transition: all 0.5s ease 0s;

                        img {
                            transition: all 0.5s ease 0s;
                        }
                    }
                }

                &:hover {
                    .status-title {
                        .info-icon {
                            background-color: #4677F0;

                            img {
                                filter: brightness(0) invert(1);
                            }
                        }
                    }
                }

                h3 {
                    font-size: 45px;
                    color: #4677f0;

                    @media (max-width: $NormalScreen) {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .finance-table-block {
        position: relative;
        z-index: 1;
        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background-color: #fff;
            background-image: url(../../assets/images/simple-loader.gif);
            background-repeat: no-repeat;
            background-position:  center 75%;
            pointer-events: none;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
        }
        .finance-table-filter {
            background: #fff;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: sticky;
            top: 0;
            z-index: 3;

            .finance-table-filter-select {
                display: flex;
                align-items: center;

                >div {
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .react-select__control {
                    min-width: 250px;
                    border: 1px solid rgba(0, 0, 0, 0.1) !important;
                    margin-right: 10px;
                    width: 100%;
                    background-color: #F7F7F7;
                }
            }

            .finance-table-searchbar {
                max-width: 590px;
                width: 100%;

                @media (max-width: $NormalScreen) {
                    max-width: 300px;
                }

                .formField {
                    margin-bottom: 0;

                    input {
                        padding: 0;
                    }

                    label {
                        display: none;
                    }
                }

                .common-search-bar {
                    min-width: 590px;
                }
            }
        }

        .finance-table-box {
            max-height: 445px;
            min-height: 434px;
            overflow-y: auto;
            overflow-x: hidden;
            @media (max-width: $NormalScreen) {
                max-height:  385px;
                min-height: 380px;
            }
            .table {
                margin: 0;
                position: relative;
                
                table {
                    table-layout: fixed;
                    tr{
                        &.delete-doctor-row{
                            td{
                                opacity: 0.5;
                                font-style: italic;
                            }
                        }
                    }
                    td {
                        &:last-child {
                            padding: 6px;
                            cursor: pointer;
                        }
                        &.editprint-link{
                            .edit-patient{
                                margin-right: 10px;
                            }
                        }
                        vertical-align: middle;

                        .more-option {
                            position: static;
                            text-align: left;
                            width: auto;

                            .dropdown {
                                padding-bottom: 0;

                                .selected {
                                    width: 8px;
                                }
                            }
                        }
                    }

                    tfoot {
                        position: sticky;
                        bottom: -1px;

                        tr {
                            filter: drop-shadow(0px -8px 6px rgba(29, 29, 29, 0.03));

                            td {
                                background-color: #EAEAEA;
                                border-color: #cac4c4;
                                color: #000000;
                            }
                        }
                    }
                }
            }
        }
    }
}
.info-icon {
	margin-left: 10px;
}
.info-icon-tooltip {
    position: relative;

    &:hover {
        .info-tooltip-data {
            opacity: 1;
            visibility: visible;
        }
    }

    .info-tooltip-data {
        position: absolute;
        min-width: 410px;
        border-radius: 4px;
        background: #fff;
        box-shadow: 6px 11px 25px rgba(0, 0, 0, 0.09);
        right: 200%;
        z-index: 4;
        top: -18px;
        padding: 15px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s ease 0s;

        @media (max-width: $NormalScreen) {
            padding: 10px;
        }

        &:before {
            content: "";
            position: absolute;
            border-style: solid;
            border-width: 9px;
            border-top-color: transparent;
            border-bottom-color: transparent;
            border-left-color: #fff;
            border-right-color: transparent;
            right: -18px;
            top: 17px;
        }

        h5 {
            font-weight: normal;
            font-size: 16px;
            margin-bottom: 10px;

            &:empty {
                display: none;
            }
        }

        p {
            font-weight: 300;
            font-size: 16px;

            @media (max-width: $NormalScreen) {
                font-size: 14px;
            }

            &:empty {
                display: none;
            }
        }
    }
}

.medicines{
    .ant-table-wrapper {
        min-height: calc(100vh - 448px);
        max-height: calc(100vh - 448px);
        overflow: auto;
        @media (max-width: $NormalScreen) {
            min-height: calc(100vh - 389px);
            max-height: calc(100vh - 389px);
        }
    }
}


.custom-range-modal{
    .modalBox .modalMain{
        max-width: 700px;
    }
    .rdrInputRanges {
        display: none;
    }
    .rdrDateRangePickerWrapper {
        display: flex;
        flex-direction: column;
        .rdrCalendarWrapper {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            .rdrDateDisplayWrapper{
                width: 49%;
                background: rgb(239, 242, 247);
                border-radius: 5px;
            }
            .rdrMonthAndYearWrapper{
                width: 49%;
                padding: 0;
                height: auto;
                background: rgb(239, 242, 247);
                border-radius: 5px;
                select:hover {
                    background-color: transparent;
                }
            }
        }
    }
    .rdrDefinedRangesWrapper{
        width: 100%;
        border: none;
        margin-bottom: 15px;
        .rdrStaticRanges{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            button{
                text-align: center;
                width: 16.76%;
                border: 1px solid var(--bordercolor);
                margin-left: -1px;
                margin-top: -1px;
                span{
                    text-align: center;
                    padding: 10px;
                }
            }
        }
    }
    .rdrDateDisplayItem {
        border: none;
        background-color: transparent;
        box-shadow: none;
    }
    .rdrDateDisplay{
        margin: 0;
        padding: 0;
        .rdrDateInput{
            position: relative;
            padding: 10px;
            text-align: left;
            &::after{
                content: "";
                position: absolute;
                top: 3px;
                right: 17px;
                bottom: 0;
                margin: auto;
                width: 20px;
                height: 20px;
                background: url("../../assets/images/line-arrow.svg") no-repeat 0 0;
                background-size: auto;
                pointer-events: none;
                background-size: 100% auto;
                transform: rotate(-90deg);
            }
            &::before{
                display: block;
                position: static;
                color: var(--blue);
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 3px;
                @media (max-width: $NormalScreen) {
                    font-size: 12px;
                }
            }
            &:last-child{
                &::after{
                    display: none;
                }
            }
            &:first-child{
                width: 55%;
                padding-right: 40px;
                padding-left: 30px;
                &::before{
                    content: "Start Date";
                }
            }
            &:nth-child(2){
                width: 45%;
                &::before{
                    content: "End Date";
                }
            }
            input{
                text-align: left;
                height: auto;
                width: auto;
                padding: 0;
                line-height: 100%;
                font-size: 16px;
                color: #000000;
                @media (max-width: $NormalScreen) {
                    font-size: 14px;
                }
            }
        }
    }

    .rdrMonths {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100%;
        justify-content: space-between;
        
        .rdrMonth{
            padding: 0;
            width: 49%;
        }
        .rdrMonthName{
            font-size: 14px;
            font-weight: bold;
            color: var(--blue);
            text-align: center;
            background-color: #eff2f7;
        }
        .rdrWeekDays{
            .rdrWeekDay{
                font-size: 12px;
                font-weight: 500;
                color: #000;
            }
        }
    }


}