@import '../../../../../style.scss';

.white-box {
    &.usersRole {
        min-width: 1046px;
        margin: 10px auto 0 auto;
    }
}

.no-bg{
    &.UsersRole {
        background: transparent; 
        .adminTableHeader {
            padding-bottom: 0;
        }
    }
}

.add_user {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-wrap: 100%;

    .add_user-option {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 25px;

        .full-width {
            width: 100%;
            justify-content: space-between;
        }

        >div {
            font-family: "Merriweather Sans";
            font-size: 16px;
            line-height: 20px;
            color: var(--blue);
            display: flex;
            align-items: center;
            margin-right: 35px;

            @media (max-width: $NormalScreen) {
                font-size: 14px;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                color: var(--text);

                >img {
                    transform: rotate(180deg);
                    transition: all 0.5s ease;
                }
            }

            >img {
                margin-left: 15px;
                transition: all 0.5s ease;

                @media (max-width: $NormalScreen) {
                    width: 20px;
                    margin-left: 10px;
                }
            }
        }
    }

    .UsersRole {
        width: 100%;
        background: transparent;
        border-radius: 0;
        padding: 0;
        border: none;
    }

    table {
        thead {
            th {
                &:first-child {
                    width: 45px;
                }
            }
        }
    }

    .AddNew {
        .modalBox {
            .modalMain {
                max-width: 660px;
            }
        }
    }
}

.modal_access {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;

    p {
        width: 100%;
        font-size: 16px;
        color: #67757C;

        @media (max-width: $NormalScreen) {
            font-size: 14px;
        }
    }

    label {
        font-size: 12px;
    }

    .formField.checkbox {
        width: 48.5%;
        margin-bottom: 10px;
    }
}

.access-action-icon {
    img {
        margin: 0 5px;
    }
}


.table.userroles {
    td {
        >.type-user {
            margin: 0 2px;

            &:after {
                content: ",";
            }

            &:last-child {
                &:after {
                    content: " ";
                }
            }
        }
    }
}