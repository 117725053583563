@import '../../style.scss';

.button {
    position: relative;
    margin-right: 10px;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;

    &.show-button-loader {
        button {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--blue);
                z-index: 1;
                border-radius: 4px;
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                height: 20px;
                width: 20px;
                background-color: transparent;
                box-sizing: border-box;
                border-radius: 50%;
                border-top: 2px solid #fff;
                border-left: 2px solid #fff;
                border-bottom: 2px solid #fff;
                border-right: 2px solid rgba(255, 255, 255, 0.35);
                transition: all 0.5s ease;
                animation-name: spin;
                animation-duration: 0.75s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                z-index: 2;
                opacity: 1;
                transition: all 0.28s ease;
                transition-delay: 0.1s;
            }
        }
    }

    &:last-child {
        margin-right: 0;
    }

    button {
        background-color: var(--blue);
        padding: 10px 20px;
        border: none;
        font-family: "Merriweather Sans";
        font-weight: normal;
        font-size: 16px;
        line-height: normal;
        text-align: center;
        color: #fff;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        border: 1px solid transparent;

        @media (max-width: $NormalScreen) {
            font-size: 14px;
        }

        //black btn
        &.black {
            background-color: #242424;
        }

        &.green {
            background-color: #1DC909;

            &:hover {
                background-color: #12b300;
            }
        }

        //outline btn
        &.btn-outline-primary {
            background-color: transparent;
            border: 1px solid #2456d2;
            color: #2456d2;

            &.red {
                background-color: transparent;
                border: 1px solid #F34444;
                color: #F34444;
            }
        }

        //outline btn hover
        &.btn-outline-primary {

            &:focus,
            &:hover {
                color: #fff;
            }

        }

        &.btn-outline-primary {
            &.red {

                &:focus,
                &:hover {
                    background-color: #F34444;
                }
            }
        }

        //reset btn
        &[type="reset"] {
            background: transparent;
            color: #D9D9D9;
            min-width: 1px !important;

            &::after {
                display: none;
            }

            &:focus,
            &:hover {
                background-color: #e3ebf7;
                color: var(--blue);
            }
        }

        &:focus,
        &:hover {
            background: #4865AC;
        }

        &.no-bg {
            background-color: transparent;
            color: #555;

            &:focus,
            &:hover {
                background-color: #e3ebf7;
                color: var(--blue);
            }
        }

        &.border {
            background-color: transparent;
            border: 1px solid var(--blue);
            color: var(--blue);

            &:focus,
            &:hover {
                color: var(--white);
                background-color: var(--blue);
            }

        }

        &[disabled] {
            background-color: #F2F2F2;
            color: #8B8989;
            pointer-events: none;
            cursor: not-allowed;
            &::before{
                filter: invert(1);
                opacity: 0.45;
            }
        }
    }
}

.right-btn {
    text-align: right;

    .button {
        display: inline-block;
        vertical-align: top;
    }
}

.center {
    text-align: center;

    .button {
        display: inline-block;
        vertical-align: top;
    }
}


.add-btn {
    button {
        &::before {
            content: "";
            background: url(../../assets/images/plus.svg) no-repeat;
            background-position: 0 1px;
            background-size: 100% auto;
            display: inline-block;
            vertical-align: 0;
            width: 14px;
            height: 14px;
            margin-right: 10px;
        }
    }
}

.delete-btn {
    button {
        padding-left: 43px;

        &.btn-outline-primary {
            background-color: #FFEBEE;
            border: 1px solid transparent;
            color: #242424;

            &:focus,
            &:hover {
                background-color: #FFCFD6;
                color: #242424;
            }
        }

        &::before {
            content: "";
            background: url(../../assets/images/delete-red.svg) no-repeat;
            background-position: 0 1px;
            background-size: 100% auto;
            width: 20px;
            height: 22px;
            position: absolute;
            left: 15px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
}


@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}