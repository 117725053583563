.tooltip {
	position: relative;
    font-weight: 300;
    font-size: 16px;
    color: var(--text);
    margin-left: 10px;
    line-height: 0;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 2px solid var(--blue);
    border-radius: 50px;
    display: inline-block;
    vertical-align: top;
    padding: 2px 0;
    text-align: center;
    opacity: 1;
    &:after {
        content: attr(data-tooltip);
        position: absolute;
        top: 150%;
        right: -40px;
        width: 307px;
        height: auto;
        background: #fff;
        filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.08));
        padding: 15px;
        border-radius: 5px;
        line-height: 20px;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all 0.5s ease;
        pointer-events: none;
        text-align: left;
    }
    &:before{
        content: "";
        position: absolute;
        top: 100%;
        right: 0;
        left: -4px;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 12px 12px 12px;
        border-color: transparent transparent #fff transparent;
        filter: drop-shadow(0px 3px 13px rgba(0, 0, 0, 0.8));
        opacity: 0;
        transition: all 0.5s ease;
    }
    &:hover{
        background-color:var(--blue);
        > img{
            filter: brightness(0) invert(1);
        }
        &:before{
            opacity: 1;
            transition: all 0.5s ease;
        }
        &:after {
            pointer-events: auto;
            max-height: 500px;
            opacity: 1;
            transition: all 0.5s ease;
        }
    }
}



.access-action-icon{
    display: flex;
    justify-content: space-between;
    > span{
        > span{
            &.cust-tooltip{
                width: 25px;
                height: 25px;
                display: inline-block;
                vertical-align: top;
                line-height: 25px;
                margin: 0 2px;
                border-radius: 100%;
            }
        }
    }
}