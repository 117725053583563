@import '../../style.scss';

.uploadlogo{
    .dragDrop{
        display: flex;
        flex-wrap: wrap;
        transition: all 0.5s ease;
        &:hover > div.upload {
          background-color: #dee8ff;
          border-color: #9fbbfd;
        }
    }
    .upload{
      background-size: contain;
      background-color: #f5f8ff;
    }
    label:empty{
      display: none;
    }
}
  .upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 390px;
    height: 91px;
    background: #fff;
    border: 1px solid #d9d9d9;
    color: #fff;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  p {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
  }

  &.is-highlight {
    background-color: rgba(#4aa0ea, 0.1);

    p {
      opacity: 1;
    }
  }

  &.is-drop {
    .upload-icon{
      opacity: 0;
    }
    p {
      opacity: 0;
    }
  }
  .file-upload {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #242424;
    line-height: 100%;
    .upload-icon {
      width: 55px;
      display: block;
      transition: all 0.5s ease;
      img{
        opacity: 0.5;
      }
    }
    p{
        margin-bottom: 0;
        color: #4f4f4f;
        font-size: 14px;
    }
  }
}

.upload-button {
  max-width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.upload-file {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.uploadfilebutton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 96px;
    height: 36px;
    border-radius: 4px;
    background: #242424;
    font-family: "Merriweather Sans";
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    margin-left: 35px;
    cursor: pointer;
    &:hover{
        background-color: var(--blue);
    }
}

.alter-text{
    font-size: 50px;
    color: black;
}