.ManageVitals-tab {
	.doctor_profile-tab-dec {
		.doctor_profile-box {
			width: 100%;
			border: none;
			padding: 0;
			position: static;
			min-height: 37px;
			margin-bottom: 0;
		}

		.add-new {
			position: static;
		}

		.Preview {
			width: 94px;
			height: 36px;
			border-radius: 4px;
			background: transparent;
			border: 1px solid #2456d2;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			font-size: 16px;
			text-align: center;
			color: #4677f0;
			cursor: pointer;
			margin-left: 25px;

			&:hover {
				background: #4677f0;
				color: #fff;
			}
		}
	}
}

.vitals-list {
	margin-top: 0;

	ul {
		max-width: 100%;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		li {
			position: relative;
			padding-right: 0;
			margin-bottom: 10px;
			width: 49%;

			&[draggable="true"] {
				.vitals-item {
					.vitals-name {
						width: auto;
						margin: 0;
					}
				}
			}
		}

		.vitals-item {
			max-width: 100%;
			width: 100%;
			height: 56px;
			border-radius: 6px;
			background: #f7f7f7;
			border: 1px solid #f7f7f7;
			position: relative;
			display: flex;
			align-items: center;
			padding: 0 15px;

			.drag-icon {
				margin-right: 20px;
			}

			.vitals-icon {
				width: 26px;
				height: 26px;
				display: block;
				margin-right: 15px;
				background-color: #ddd;
			}

			.vitals-name {
				font-weight: normal;
				font-size: 15px;
				line-height: 20px;
				text-align: left;
				color: #242424;
				margin-right: 15px;
				width: 130px;
			}

			.vitals-measeur {
				font-size: 15px;
				line-height: 20px;
				text-align: center;
				color: #242424;
			}

			.vitals-measeure,
			.vitals-range {
				display: flex;
				align-items: center;
				max-width: 100px;
				width: 100%;
				margin-right: 25px;

				.formField {
					margin: 0 2px;

					label {
						display: none;
					}

					.dropdown {
						.icon {
							top: 13px;
						}
					}

					.dropdownHeader,
					input {
						font-weight: 300;
						font-size: 15px;
						line-height: 30px;
						text-align: center;
						color: #242424;
						height: 30px;
						border-bottom-width: 0;

						&:active,
						&:focus,
						&:hover {
							border-color: var(--blue);
						}
					}
				}
			}

			.vitals-measeure {
				margin-right: 0;

				.dropdown {
					min-width: 1px;
				}
			}
		}

		.vital-action {
			position: absolute;
			top: 13px;
			right: 0;
			display: flex;

			.delete,
			.edit {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 30px;
				height: 30px;
				background: #fff;
				border: 1px solid #eaeaea;
				border-radius: 50px;
				margin-right: 10px;
				cursor: pointer;
				padding: 6px;

				&.delete {
					margin-right: 0;
				}

				&:hover {
					background-color: #eaeaea;
				}
			}

			.update {
				position: absolute;
				right: 0;
				top: 0;
				opacity: 0;
				transition: all 0.5s ease;
				pointer-events: none;

				.btn {
					width: 78px;
					height: 35px;
					border-radius: 4px;
					background: #4677f0;
					font-size: 15px;
					text-align: center;
					color: #fff;
					line-height: 20px;
				}

				&.showupdate {
					opacity: 1;
					transition: all 0.5s ease;
					pointer-events: auto;
				}
			}
		}
	}
}

.modalBox {
	.vitals-list {
		margin-bottom: 25px;

		ul {
			li {
				padding: 0;
			}

			.vitals-item {
				max-width: 100%;
				background-color: #fff;
				border: 1px solid #d9d9d9;
				overflow: hidden;

				.vitals-range {
					.formField {
						input {
							border-bottom-width: 1px;
							max-width: 100%;
						}
					}
				}

				.vitals-name {
					width: auto;
					margin-right: 0;
				}

				.vitals-name,
				.vitals-icon {
					position: relative;
					z-index: 1;
					pointer-events: none;
				}

				.checkboxs {
					position: absolute;
					z-index: 0;
					top: 0;
					left: 0;
					right: 0;
					width: 100%;
					height: 100%;
					cursor: pointer;

					.formField {
						margin: 0;
						height: 100%;

						label {
							margin: 0;
							height: 100%;
							border: none;

							&::before {
								width: 100%;
								height: 100%;
								border: none;
								background-position: 95% center;
								background-size: 15px;
							}
						}

						input[type="checkbox"]:checked+label:before {
							background-color: #e3ebf7;
							background-image: url("../../../../assets/images/tick-blue.svg");
						}
					}
				}
			}
		}
	}
}

.EditVitals-Popup {
	.vitals-list {
		ul {
			max-width: 100%;
		}
	}
}