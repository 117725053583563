.MamageSettingCheckBox {
    .checkbox {
        position: relative;
        background: #F2F6FF;
        border-radius: 2px;
        padding: 8px;
        margin-bottom: 8px;
        cursor: pointer;
        input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            margin: 0;
            z-index: -1;
            height: auto;
            width: auto;

            &:checked {
                +label {
                    &:before {
                        background-color: var(--blue);
                        background-image: url(img/check-tick.png);
                        border-color: var(--blue);
                        transition: all 0.5s ease;
                        pointer-events: auto;
                    }
                }
            }
        }

        >label {
            position: relative;
            cursor: pointer;
            padding-left: 25px;
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            pointer-events: none;
            margin-bottom: 0;
            &:before {
                transition: all 0.5s ease;
                content: "";
                position: absolute;
                left: 0;
                top: 1.5px;
                height: 17px;
                width: 17px;
                border-radius: 3px;
                border: 1px solid var(--blue);
                background-repeat: no-repeat;
                background-position: center center;
                pointer-events: auto;
            }

        }

    }
}