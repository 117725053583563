@import '../../style.scss';

.MamageSettingRadio{
    .radio{
        position: relative;
        input[type="radio"]{
            position:absolute;
            opacity: 0;
            margin: 0;
            z-index: -1;
            &:checked{
                + label{
                    &:before{
                       display: none;
                    }  
                    .select{
                        background: var(--blue);
                        border: 1px solid #4677F0;
                        color: #fff;
                        padding: 6px 10px;
                        margin-top: 10px;
                        img{
                            filter: invert(1) brightness(100);
                            margin-right: 6px;
                            width: 15px;
                        }
                    }
                }
            }
        }
        > label{
            position: relative;
            cursor: pointer;
            padding-left: 0;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: #000000;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &:before{
                display: none;
            }
            span.title{
                display: block;
                max-width: 150px;
                text-align: center;
                margin: 10px auto  0 auto;
                min-height: 37px;
            }
            .select{
                background: #FFFFFF;
                border: 1px solid var(--blue);
                border-radius: 3px;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #4677F0;
                display: inline-block;
                vertical-align: top;
                padding: 6px 10px;
                margin-top: 10px;
                @media (max-width: $NormalScreen) {
					font-size: 12px;
				}
                svg{
                    margin-right: 5px;
                    path{
                        fill: #fff;
                    }
                }
            }
        }
        
    }
}