.radio{
    position: relative;
    input[type="radio"]{
        position:absolute;
        opacity: 0;
        margin: 0;
        z-index: -1;
        height: auto;
        &:checked{
            + label{
                &:before{
                    background-color: var(--blue);
                    border-color: var(--blue);
                    box-shadow: 0 0 0px 3px #fff inset;
                    transition: all 0.5s ease;
             }  
            }
        }
    }
    > label{
        position: relative;
        cursor: pointer;
        padding-left: 25px;
        margin-bottom: 0;
        &:before{
            transition: all 0.5s ease;
            content: "";
            position: absolute;
            left: 0;
            top: 1.5px;
            height: 17px;
            width: 17px;
            border-radius: 50px;
            border: 1px solid #67757c;
        }
        
    }
    
}