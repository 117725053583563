@import "../../../../style.scss";

.Hospital-left-tab {
    .select-hospital {
        max-width: 20%;
        width: 100%;
        position: relative;

        .react-select__control {
            background-color: #fff;
            margin-bottom: 10px;
        }

        label {
            display: none;
        }

        .dropdown {
            .icon {
                top: 24px;
                right: 20px;
            }

            .dropdownHeader {
                height: 53px;
                border-radius: 4px;
                background: #fff;
                border: 1px solid #d9d9d9;
                line-height: 53px;
                padding-left: 30px;
                font-size: 18px;
                color: var(--blue);
            }
        }
    }
}



.ant-tabs {
    flex-wrap: wrap;
    overflow: visible;
    height: auto !important;

    >.ant-tabs-nav {
        max-width: 100%;
        width: 100%;
        background: #fff;
        border: 1px solid var(--bordercolor);

        &::before {
            display: none;
        }
    }

    .ant-tabs-nav-wrap {
        border: none;

        &::before {
            display: none;
        }
    }

    .ant-tabs-nav-list {
        background: transparent;

        .ant-tabs-ink-bar {
            display: none;
        }

        .ant-tabs-tab {
            padding: 14px 22px;
            font-family: "Merriweather Sans";
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            color: #242424;
            margin: 0;
            border: none;
            background: transparent;

            @media (max-width: $NormalScreen) {
                font-size: 12px;
            }

            &::before {
                display: none;
            }

            &:hover,
            &.ant-tabs-tab-active {
                background: var(--blue);
                color: var(--white);
            }


            >div {
                color: inherit;
                text-shadow: none;
            }

        }
    }

    .ant-tabs-content-holder {
        background: transparent;
        border: none;
        margin: 0;
        padding: 0;
        position: relative;
        max-width: 100%;

        .ant-tabs-content {
            .ant-tabs-tabpane {
                padding: 0;
            }
        }

        .tab-list {
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

}


/*if tab is vertical add clas vartical-tab*/
.vertical-tab {
    >.ant-tabs {
        >.ant-tabs-nav {
            max-width: 18%;
            width: 100%;

            @media (max-width: $NormalScreen) {
                max-width: 16%;
            }

            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    padding: 14px 35px 14px 14px;
                    margin: 0;
                    position: relative;
                    border-bottom: 1px solid var(--bordercolor);
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        right: 15px;
                        top: 0;
                        bottom: 0;
                        background: url("../../../../assets/images/tab-arrow.svg") no-repeat 0 0;
                        background-size: 100% auto;
                        display: block;
                        width: 10px;
                        height: 17px;
                        margin: auto;
                    }

                    &:hover,
                    &.ant-tabs-tab-active {
                        &::before {
                            filter: invert(1) brightness(1000);
                        }
                    }

                    p {
                        font-size: inherit;
                        font-family: inherit;
                        font-weight: inherit;
                    }
                }
            }
        }

        >.ant-tabs-content-holder {
            margin-left: 15px;
            max-width: calc(100% - 18% - 15px);
            @media (max-width: $NormalScreen) {
                max-width: calc(100% - 16% - 15px);
            }
        }
    }
}

.tab-white-bg {
    background-color: var(--white);
    border: 1px solid var(--bordercolor);
    padding: 20px;
    margin-bottom: 15px;
    width: 100%;
    &:last-child{
        margin-bottom: 0;
    }
    &.p-0{
        padding: 0;
    }
}


.hospital-setting {
    >.vertical-tab {
        >.ant-tabs {
            >.ant-tabs-content-holder {
                padding: 0;
                background: transparent;
                border: none;
            }
        }

        .horizontal-tab {
            .ant-tabs-content-holder {
                // background-color: var(--white);
                // padding: 20px;
                // border: 1px solid var(--bordercolor);
                min-height: 59vh;
            }
        }
    }
}