
@import "../../../style.scss";

.add-special-date{
    margin-top: 25px;   
}

.remove-field{
    background: #DE350B;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    @media (max-width: $NormalScreen) {
        width: 16px;
        height: 16px;
    }
}
