@import "../../../../style.scss";

.manual-signature-box{
    img{
        max-height: 100px;
    }
}

.doctor_profile-tab-dec{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
    .doctor_profile-box {
        width: 49.2%;
        border: 1px solid var(--bordercolor);
        background: #fff;
        position: relative;
        padding: 20px 25px;
        margin-bottom: 20px;
        .adminTableHeader{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;
            .tableTitle{
                margin-bottom: 0;
            }
        }
        h3{
            font-size: 22px;
            line-height: 120%;
            color: #242424;
            margin-bottom: 20px;
            font-weight: 600;
        }
        .password-action-field{
            .passwordeyeicon {
                top: 38px;
            }
        }
        .tableTitle{
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        .edit-icon {
            position: absolute;
            right: 15px;
            top: 15px;
            span{
                width: 30px;
                height: 30px;
                background: #fff;
                border: 1px solid #eaeaea;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                cursor: pointer;
                &:hover{
                    background: #eaeaea;
                }
            }
        }
        .add-new{
            position: absolute;
            right: 25px;
            top: 25px;
            .add-click{
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 16px;
                line-height: 20px;
                text-align: left;
                color: #4677f0;
                &:hover{
                    img{
                        transform: rotate(180deg);
                        transition: all 0.5s ease;
                    }
                }
                img{
                    margin-left: 10px;
                    width: 29px;
                    height: 29px;
                    transition: all 0.5s ease;
                }
            }
        }
        address{
            max-width: 550px;
            margin-bottom: 15px;
            padding-bottom: 4px;
            border-bottom: 1px solid #f4f4f4;
            @media (max-width: $NormalScreen) {
                margin-bottom: 15px;
                padding-bottom: 10px;
            }
            &:last-child{
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
            p:empty {
                display: none;
            }
            h4{
                position: relative;
                font-family: "Merriweather Sans";
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-align: left;
                color: #242424;
                display: inline-block;
                vertical-align: top;
                padding-right: 50px;
                margin-bottom: 12px;
                @media (max-width: $NormalScreen) {
                    font-size: 14px;
                }
               + p{
                   font-weight: 400;
               }
               .edit-icon{
                   top: -4px;
                   right: 0;
               }
            }
            p{
                font-family: "Merriweather Sans";
                font-weight: 300;
                font-size: 14px;
                line-height: 20px;
                text-align: left;
                color: #242424;
                line-height: 130%;
                margin-bottom: 10px;
                opacity: 0.75;
                @media (max-width: $NormalScreen) {
                    font-size: 12px;
                }
               }
        }
    }
    .doctor_profile-fill-data{
        .detail-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            margin-bottom: 15px;
            > label{
                display: block;
                font-size: 16px;
                line-height: 26px;
                text-align: left;
                color: #67757c;
                opacity: 0.99;
                min-width: 170px;
                font-weight: 600;
                margin-bottom: 0;
                + .select{
                    margin-left: 0;
                }
            }
            > .coma-list,
            > span{
                font-size: 16px;
                line-height: 26px;
                text-align: left;
                color: #242424;
                max-width: calc(100% - 170px);
                margin-top: 0;
            }
            > .coma-list{
                display: flex;
                flex-wrap: wrap;
                span{
                    font-size: 16px;
                    @media (max-width: $NormalScreen) {
                        font-size: 14px;
                    }
                }
            }
            .add,
            .remove{
                margin-left: 10px;
                width: 20px;
                height: 20px;
                cursor: pointer;
                line-height: 0;
                transition: all 0.5s ease;
                &:hover{
                    transform: rotate(180deg);
                    transition: all 0.5s ease;
                }
            }
        }
        .select{
            max-width: 210px;
            width: 100%;
            margin-left: 30px;
            &:first-child{
                margin-left: 0;
            }
            div.react-select__control{
                .react-select__indicators{
                    filter: invert(1) brightness(0);
                }
            }
        }
    }
}

.digital-signature{
    .digital-signature-box{
        background-color: #fff;
        h6{
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;
            color: #000000;
            margin-bottom: 5px;
            @media (max-width: $NormalScreen) {
                font-size: 16px;
            }
        }
        p{
            margin-bottom: 3px;
            font-weight: 300;
            font-size: 14px;
            line-height: 150%;
            color: #242424;
            strong{
                margin-right: 5px;
            }
            @media (max-width: $NormalScreen) {
                font-size: 14px;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}