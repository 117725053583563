@import "../../../style.scss";

.main-doc-screen {
	padding: 97px 50px 30px 97px;
	min-height: 100vh;
	display: flex;
	flex-wrap: wrap;
	@media (max-width: $NormalScreen) {
		padding: 73px 15px 40px 73px;
    }
}

.doctormain-screen{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	position: relative;
	.doctor_profile-tab {
		width: 100%;
		margin-top: 0;
	}
}
.contatcformField{
	.formField.contactformField{
		display: flex;
		label{
			width: 100%;
		}
		> input{
			width: calc(100% - 70px);
		}
	}
}
