.normal-hours{
    .odp-hours {
        min-height: 50px;
    }
}

.set-time{
    .select-day{
        ul{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 25px;
            .select-multi-day{
                position: relative;
                .formField {
                    margin: 0;
                    label{
                        padding: 13px 16px;
                        background: #e3e3e3;
                        border: 1px solid #b0b0b0;
                        margin: 0 -1px;
                        color: #000;
                        &::after,
                        &::before{
                            display: none;
                        }
                    }
                    input[type="checkbox"]:checked + label{
                        background-color: var(--blue);
                        color: #fff;
                    }
                }
            }
        }
    }
    .add-field{
        cursor: pointer;
    }
    .add-time{
        .time-field{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            position: relative;
            padding-right: 35px;
            .formField {
                width: 48%;
                label{
                    display: none;
                }
            }
            .remove-field{
                position: absolute;
                right: 0;
                top: 10px;
                cursor: pointer;
                z-index: 1;
            }
        }
    }
}
