@import '../../../style.scss';

div.single-tmp-footer {
	margin-top: 0px;
	padding: 45px 0 5px 0;

	h4 {
		font-weight: bold;
		margin-bottom: 5px;
		font-size: 36px;
		color: rgba(0, 0, 0, 0.85);
	}

	p {
		margin-bottom: 5px;
		font-size: 14px;
		color: #57585A;
		font-weight: 400;
	}

	.footer {
		height: auto;
		text-align: left;
		position: static;
		border: none;
		justify-content: center;
	}

	&.tmp-footer-1 {
		.footer-row {
			.tmp-foot-contactinfo {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	&.tmp-footer-2 {
		.footer-row {
			.tmp-foot-contactinfo {
				li:nth-child(2) {
					padding-right: 0;
				}
			}
		}
	}

	&.tmp-footer-4,
	&.tmp-footer-3 {
		.footer-row {
			.tmp-foot-contactinfo {
				li {
					width: 65%;
				}

				li:last-child {
					width: 35%;
					display: flex;
					justify-content: flex-end;
				}
			}
		}
	}

	.footer-row {
		.tmp-foot-contactinfo {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;

			li {
				color: #57585A;
				font-size: 14px;
				display: flex;
				align-items: center;

				&:not(:last-child) {
					padding-right: 15px;
				}

				>div {
					display: flex;
					align-items: center;
					margin-right: 15px;

					&:last-child {
						margin-right: 0;
					}
				}

				@media (max-width: $NormalScreen) {
					font-size: 14px;
				}

				&.fullwidth {
					width: 100%;
					margin-top: 10px;
				}
			}

			.conpicon {
				width: 30px;
				min-width: 30px;
				height: 30px;
				border-radius: 4px;
				background-color: #304e97;
				-webkit-print-color-adjust: exact;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 5px;

				img {
					width: 13px;
				}
			}
		}
	}

	&.tmp-footer-2 {
		.tmp-foot-contactinfo {
			li {
				flex-direction: column;
			}
		}
	}
}





.manage-setttings-prescriptions {
	div.header-footer-preview {
		.footer-preview {
			.tmp-foot-contactinfo {
				.conpicon {
					width: 20px;
					min-width: 20px;
					height: 20px;
					padding: 5px;
				}

				div {
					font-size: 12px;
					color: rgba(0, 0, 0, 0.85);

					@media (max-width: $NormalScreen) {
						font-size: 11px;
					}
				}
			}
		}
	}
}