@import "../../../../style.scss";

.drm-OPDSetting-tab {
    .tab-list {
        margin-bottom: 20px;
    }

    >div {
        .white-box {
            min-width: 1px;
            width: 100%;

            >div {
                .heading {
                    border-bottom: 1px solid #D3D3D3;
                    padding-bottom: 10px;
                }
            }
        }
    }

    .opd_setting {
        .odp-hours {
            ul {
                li {
                    .day {
                        width: 25%;
                        font-size: 14px;

                        @media (max-width: $NormalScreen) {
                            font-size: 12px;
                            width: 75px;
                            margin-top: 10px;
                            padding-right: 0;
                        }
                    }

                    .switch-box {
                        width: 22%;

                        @media (max-width: $NormalScreen) {
                            width: 100px;
                        }
                    }

                    .add-time {
                        @media (max-width: $NormalScreen) {
                            width: calc(100% - 40% - 12px);
                        }
                    }
                }
            }
        }
    }

    .react-date-picker__inputGroup__input {
        font-weight: normal;
        font-size: 11px;
    }
}

.opd_setting {
    .form-progress {
        max-width: 620px;

        .bar {
            max-width: 620px;
        }

        >ul {
            >li {
                width: 33.33%;
            }
        }
    }

    .white-box {
        min-width: 1250px;
        max-width: 100%;
        margin-top: 0;
    }

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        h2 {
            margin: 0;
            font-size: 22px;
            line-height: 30px;
            font-weight: 900;

            @media (max-width: $NormalScreen) {
                font-size: 18px;
            }

            span.tooltip {
                margin-top: 5px;
            }
        }

        span {
            font-family: "Merriweather Sans";
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            text-align: left;
            color: var(--blue);
            cursor: pointer;

            &:hover {
                color: var(--text);
            }
        }
    }

    .opd-step-1 {
        .white-box {
            display: flex;
            flex-wrap: wrap;

            div.normal-hours,
            div.special-hours {
                width: 48%;
                padding-right: 45px;
                border-right: 1px solid rgba(0, 0, 0, 0.2);

                @media (max-width: $NormalScreen) {
                    padding-right: 15px;
                }
            }

            div.special-hours {
                padding-left: 45px;
                padding-right: 0;
                border-right: none;
                width: 52%;

                @media (max-width: $NormalScreen) {
                    padding-left: 15px;
                }
            }

            .action-field {
                width: 100%;

                &.right {
                    text-align: right;
                }
            }
        }
    }

    .special-hours {
        .odp-hours {
            ul {
                li {
                    .add-time {
                        @media (max-width: $NormalScreen) {
                            width: calc(100% - 42% - 15px);
                        }
                    }

                    .day {
                        padding-right: 0;
                        margin-right: 0;
                        margin-top: 0;

                        .formField {
                            margin-bottom: 10px;

                            input {
                                height: 40px;
                                width: 100px;
                                font-size: 12px;
                                padding: 0 8px;
                            }
                        }
                    }

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .special-hours {
        .odp-hours {
            ul {
                li {
                    .day {
                        padding-right: 0;
                        width: 105px;
                        @media (max-width: $NormalScreen) {
                            width: 92px;
                        }
                        .formField input{
                            @media (max-width: $NormalScreen) {
                                width: 92px;
                            }
                        }
                    }

                    .react-datepicker-wrapper {
                        min-height: 30px;
                    }
                }
            }
        }
    }

    .odp-hours {
        ul {
            li {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                position: relative;
                padding-left: 30px;
                min-height: 40px;

                @media (max-width: $NormalScreen) {
                    padding-left: 22px;
                }

                >span.remove-field {
                    position: absolute;
                    left: 0;
                    line-height: 0;
                    cursor: pointer;
                    top: 11px;

                    &:hover {
                        transform: rotate(90deg);
                        transition: all 0.5s ease;
                    }
                }

                &::after {
                    display: none;
                }

                .day {
                    margin-top: 10px;
                    width: 30%;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--text);

                    .react-date-picker {
                        margin-top: -10px;
                    }
                }

                .switch-box {
                    width: 20%;
                    margin-top: 9px;

                    .switch-wrap>label {
                        margin-bottom: 0;
                    }
                }

                .add-time {
                    position: relative;
                    width: calc(100% - 45% - 15px);

                    .remove-field,
                    .add-field {
                        display: block;
                        position: absolute;
                        right: 0;
                        bottom: 8px;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                        z-index: 1;
                        transition: all 0.5s ease;
                        line-height: 0;

                        @media (max-width: $NormalScreen) {
                            width: 16px;
                            height: 16px;
                            bottom: 11px;
                        }

                        &:hover {
                            transform: rotate(90deg);
                            transition: all 0.5s ease;
                        }
                    }

                    .add-field {
                        bottom: 21px;

                        @media (max-width: $NormalScreen) {
                            bottom: 22px;
                        }
                    }

                    .remove-field {
                        background: #DE350B;
                        width: 20px;
                        height: 20px;
                        border-radius: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 1px;

                        @media (max-width: $NormalScreen) {
                            width: 16px;
                            height: 16px;
                        }
                    }


                }

                .time-field {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding-right: 25px;
                    margin-bottom: 10px;

                    @media (max-width: $NormalScreen) {
                        padding-right: 22px;
                    }

                    .formField {
                        width: 48%;
                        margin: 0;

                        >label {
                            display: none;
                        }

                        >input {
                            height: 40px;
                            width: 100%;
                            font-size: 12px;
                            padding: 0 6px;
                            letter-spacing: -0.2px;
                            
                            @media (max-width: $NormalScreen) {
                                font-size: 12px;
                            }
                        }
                    }

                    &:last-child .remove-field {
                        display: none;
                    }

                }
            }
        }
    }
}

.normal-hours {
    .odp-hours ul li {
        padding-left: 0;
    }
}