@import '../../style.scss';

.speciality-name{
    display: flex;
    align-items: center;
    .payment-icon {
        margin-right: 15px;
        border-radius: 50px;
        overflow: hidden;
        width: 40px;
        height: 40px;
        background: #fff;
        border: 2px solid rgba(62, 121, 247, 0.3);
        img{
            width: 100%;
            height: 100%;
            max-height: 100%;
            max-width: 100%;
            object-fit: cover;
        }
    }
}

.formField.Specialty div.react-select__menu {
    top: auto;
    bottom: 100%;
}