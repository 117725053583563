@import "../../../style.scss";

.doctor_profile-detail {
	background: var(--white);
	border: 1px solid #d9d9d9;
	width: 100%;
	min-height: 1px;
	padding: 15px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 25px;

	.doctor_profiel_img {
		width: 125px;
		height: 125px;
		border-radius: 4px;
		overflow: hidden;
		border: 1px solid #ccc;

		.upload {
			width: 125px;
			height: 125px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.doctor_profiel_data {
		max-width: calc(100% - 125px);
		width: 100%;
		padding: 5px 0px 5px 15px;

		h2 {
			font-size: 32px;
			line-height: 30px;
			text-align: left;
			color: #242424;
			margin-bottom: 30px;

			@media (max-width: $NormalScreen) {
				font-size: 22px;
				margin-bottom: 15px;
			}
		}

		ul {
			display: flex;
			flex-wrap: wrap;

			li {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				margin-right: 2%;
				position: relative;
				width: 20%;
				text-align: left;

				&:last-child {
					width: 55%;
				}

				.simple-tag-list {
					margin-left: 30px;

					span {
						height: 30px;
						background: #fff;
						border: 1px solid #ccc;
						position: relative;
						border-radius: 50px;
						display: inline-block;
						vertical-align: top;
						margin: 0 5px 6px 0;
						font-size: 14px;
						padding: 0px 10px;
						line-height: 28px;

						@media (max-width: $NormalScreen) {
							font-size: 13px;
						}
					}

					@media (max-width: $NormalScreen) {
						margin-left: 22px
					}
				}

				&:last-child {
					margin-right: 0;
				}

				label {
					font-weight: 300;
					font-size: 14px;
					line-height: 20px;
					color: #67757c;
					display: flex;
					align-items: center;
					margin-bottom: 5px;

					@media (max-width: $NormalScreen) {
						font-size: 13px;
					}

					>span {
						margin-right: 10px;
						width: 20px;
						height: 20px;

						@media (max-width: $NormalScreen) {
							width: 18px;
							height: 18px;
							margin-right: 5px;
							margin-top: -4px;
						}

						svg {
							width: 100%;
							height: 100%;
						}
					}
				}

				>span {
					display: block;
					font-size: 14px;
					line-height: 26px;
					color: #242424;
					margin-left: 30px;
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;

					@media (max-width: $NormalScreen) {
						margin-left: 23px;
						font-size: 13px;
					}
				}
			}
		}
	}
}