@import '../../style.scss';

.twoColsField{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 18px;
    .formField{
        width: 47%;
    }
}
.threeColsField{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .formField{
        width: 31%;
    }
}
.actionField{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    &.right{
        justify-content: flex-end;
    }
    &.center{
        justify-content: center;
    }
    &.left{
        justify-content: flex-start;
    }
    .formField{
        margin-bottom: 0;
    }
 
}
.errorBox{
    color: red;
    margin-left: 0;
    font-size: 12px;
    display: block;
    text-align: left;
    margin-top: 5px;
}
.formField{
    position: relative;
    margin-bottom: 18px;
    // &.active{
    //     >label{
    //         color: var(--blue);
    //         .errorBox{
    //             color: var(--blue);
    //         }
    //     }
    // }
    .dropback{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    > label{
        font-family: "Merriweather Sans";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: #4f4f4f;
        min-height: 20px;
        display: block;
        margin-bottom: 8px;
        letter-spacing: 0.3px;
        @media (max-width: $NormalScreen) {
            font-size: 14px !important;
        }
    }
    > label:empty{
        display: none;
    }
    > label{
        > div:empty{
            display: none;
        }
    }
    .react-datepicker-wrapper .react-datepicker__input-container > input,
    > input{
        width: 100%;
        height: 42px;
        background: #fff;
        border: 1px solid var(--bordercolor);
        padding: 0 10px;
        font-family: "Merriweather Sans";
        font-weight: 400;
        font-size: 14px;
        color: var(--text-black);
        border-radius: 5px;
        transition: all 0.5s ease;
        @media (max-width: $NormalScreen) {
            font-size: 14px;
        }
        &:hover,
        &:active,
        &:focus{           
            border-color: var(--blue);
            transition: all 0.5s ease;
        }
    }
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        -moz-appearance: none  !important;
        appearance: none  !important;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
    button:not(.react-datepicker__navigation),
    input[type="button"],
    input[type="submit"],
    input[type="reset"]{
        background-color: var(--blue);
        width: auto;
        border-radius: 5px;
        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
        text-align: center;
        color: #fff;
        padding: 12px 25px;
        height: auto;
        border: none;
        cursor: pointer;
        &:hover{
            background-color: #4264B9;
        }
    }
    input[type="reset"]{
        background-color: transparent;
        color: #ACACAC;
        &:hover{
            background-color: transparent;
            color: #333;
        }
    }
}
form > div:nth-last-child(2) .dropdown .dropdownBody {
    top: auto;
    bottom: 0;
}
form > div:nth-last-child(2) .dropdown .dropdownBody.open {
	bottom: 100%;
}
.formField > label > .feildRequired {
    display: flex;
    align-items: flex-start;
}
.formField > label > .feildRequired > span {
    margin-top: 0 !important;
    margin-left: 3px;
}

.formField.WhatsappformField{
    .CopyContactCheckbox{
        position: absolute;
        right: 0;
        z-index: 1;
        .formField{
            margin-bottom: 0;
            width: 100%;
            label{
                font-size: 10px;
                &::after{
                    display: none;
                }
            }       
        }
    }
}

.formField {
    .formField {
        margin-bottom: 0;
    }
}

.twoColsField{
    .formField {
        margin-bottom: 0;
    }
}

.calender-field{
    position: relative;
    > img{
        position: absolute;
        left: 15px;
        bottom: 14px;
        z-index: 1;
    }
    .react-datepicker-wrapper .react-datepicker__input-container > input{
        padding-left: 40px;
    }
}
