@import '../../style.scss';

.Add-New_Plan {
    .modal-inner-scroll {
        .Price-plan-name-box {
            margin: 0 0 30px 0;
            padding: 20px;
            background: #f5f8ff;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border: 1px solid rgb(177, 198, 247);
            justify-content: space-between;

            >.formField {
                width: 48%;
            }

            >* {
                margin-bottom: 0;
            }

            .contactformField {
                width: 24%;

                >.formField {
                    margin-bottom: 0;
                    width: 100%;
                }
            }

            >label {
                margin-bottom: 0;
                min-width: 220px;
                color: rgba(0, 0, 0, 0.85);
                font-weight: 500;
                font-size: 16px;
                letter-spacing: 0;
                min-height: 1px;
                line-height: 140%;
            }

            >input {
                width: calc(100% - 220px);
            }
        }
    }

    .main-module {
        margin: 0 0 15px 0;
        padding: 20px 20px 0px 20px;
        background: #fafafa;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #e8e8e8;

        &:nth-last-child(2) {
            margin-bottom: 60px;
        }

        h5 {
            margin-bottom: 0;
            min-width: 220px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0;
            min-height: 1px;
            line-height: 140%;
            margin-top: 0;
        }

        .full-width {
            .contactformField {
                max-width: 48%;
            }

            .radio-buttons {
                display: flex;

                .formField {
                    &.radio {
                        margin-right: 2%;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .sub-module {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            width: calc(100% - 220px);

            >* {
                width: 48%;
            }
            &.single-field{
                flex-direction: column;
            }
            .extra-field-labels {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .add-group{
                    display: none;
                }
                &:last-child{
                    .add-group{
                        display: block;
                    }
                }
                .formField{
                    width: calc(100% - 70px);
                    margin-bottom: 0;
                }
                .action{
                    width: 70px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    span{
                        margin: 0 2px;
                        cursor: pointer;
                        &:hover{
                            filter: brightness(100) invert(1);
                            opacity: 0.4;
                        }
                    }
                }
            }

            .full-width {
                width: 100%;
            }

            >.formField {
                margin-bottom: 20px;
                margin-right: 2%;

                &:last-child {
                    margin-right: 0;
                }

                &.checkbox {
                    width: auto;

                    label {
                        margin-bottom: 0;
                    }
                }
            }

            .contactformField {
                display: block;
                width: 100%;
                margin-bottom: 20px;

                .formField {
                    margin-bottom: 0;

                    label {
                        margin-bottom: 0;
                    }
                }

                .ex-label {
                    .feildRequired {
                        display: flex;
                        align-items: flex-start;

                        .errorBox {
                            margin-top: 0;
                        }
                    }

                    label {
                        line-height: 20px;
                        color: #4f4f4f;
                        min-height: 20px;
                        display: block;
                        margin-bottom: 8px;
                        letter-spacing: 0.3px;
                    }
                }
            }

            .usernumber {
                display: flex;
                align-items: center;
                width: auto;

                >span {
                    min-width: 70px;
                    margin-left: 10px;
                    color: #4f4f4f;
                    letter-spacing: 0.3px;
                    font-weight: 600;
                }
            }
        }
    }
}

.AddON-New {
    .modalMain {
        .contactformField {
            .usernumber {
                width: 100%;
            }
        }
    }
}