.opd-step-3 {
    .white-box {
        min-width: 620px;
        min-height: 245px;
    }
}

.Opd_Services {
    .Waiting_Time_sm_title {
        margin-top: 15px;

        h3 {
            font-weight: bold;
            font-size: 16px;
            color: #4677f0;
            padding-bottom: 10px;
        }
    }

    .Waiting_Time_info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        p {
            font-size: 14px;
            color: #67757c;
            margin-bottom: 0;
            font-weight: 300;
            margin-right: 10px;
        }
    }
}

.mins_number_block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.mins_number_field {
    display: flex;
    align-items: center;
    width: 100px;
    position: relative;
    margin-right: 10px;

    .formField {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 0;
        width: 100px;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 100%;

        input {
            padding-right: 30px;
        }
    }

    .mins_number_arrow {
        display: flex;
        flex-direction: column;
        min-width: 10px;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        justify-content: center;

        span {
            line-height: 0;
            margin: 2px 0;
            cursor: pointer;
        }
    }
}



.twoColsField.formField.twoColsFieldFix.plot-adress-field {
    .formField.PlotNoField {
        width: 30%;

        .formField {
            width: 95%;
        }
    }

    .formField.LocationformField {
        width: 70%;
    }
}