@import '../../style.scss';

.counter {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;

    .counterBox {
        min-width: 24.2%;
        background: #fff;
        margin-right: 1%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        border: 1px solid var(--bordercolor);
        background-color: #fff;
        border-radius: 10px;
        transition: .5s;
        padding: 30px;
        position: relative;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column-reverse;
        overflow: hidden;

        @media (max-width: $NormalScreen) {
            padding: 23px 25px;
        }

        &:nth-child(4n) {
            margin-right: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            transition: .5s;
            background-image: url("../../assets/images/diamond.png");
        }

        &:hover {
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);

            .image {
                transform: rotate(-15deg) scale(2);

                @media (max-width: $NormalScreen) {
                    transform: rotate(-15deg) scale(1.5);
                }
            }
        }

        >span {
            display: block;
            margin-top: 8px;
            font-size: 45px;
            font-weight: 600;
            color: var(--blue);
            line-height: 1;

            @media (max-width: $NormalScreen) {
                font-size: 32px;
            }
        }

        >label {
            font-weight: 600;
            font-size: 18px;
            line-height: 100%;
            color: var(--menu);

            @media (max-width: $NormalScreen) {
                font-size: 16px;
            }
        }

        // &:last-child{
        //     span{
        //         color: var(--green);
        //     }
        // }
        .image {
            position: absolute;
            right: -40px;
            width: 146px;
            bottom: 0px;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.05;
            transform: rotate(-25deg) scale(1.7);
            transition: all 0.5s ease;

            @media (max-width: $NormalScreen) {
                right: -50px;
                bottom: -12px;
                transform: rotate(-25deg) scale(1.2);
            }
        }
    }

    .ant-skeleton {

        .ant-skeleton-paragraph,
        .ant-skeleton-content {
            display: flex;

            li,
            .ant-skeleton-title {
                margin: 0;
                margin-right: 0px;
                width: 32.1% !important;
                padding-top: 8.2%;
                border-radius: 10px;
                margin-right: 1.85%;
                height: auto;
            }

            li {
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .ant-skeleton-paragraph {
            margin-top: 0;
            max-width: var(100% - 23.6%);
            width: 100%;
        }
    }
}