@import '../../../style.scss';

.Payments {
    .add-new {
        font-family: "Merriweather Sans";
        font-size: 16px;
        line-height: 20px;
        color: var(--blue);
        display: flex;
        align-items: center;
        margin-right: 0;
        cursor: pointer;
        transition: all 0.5s ease;
        justify-content: flex-end;

        img {
            margin-left: 15px;
            transition: all 0.5s ease;
        }

        &:hover {
            color: var(--text);

            >img {
                transform: rotate(180deg);
                transition: all 0.5s ease;
            }
        }
    }

    >ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        min-height: 49vh;
        align-content: flex-start;

        li {
            width: 32%;
            max-height: 60px;
            border-radius: 3px;
            background: #fff;
            border: 1px solid #d9d9d9;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 15px;

            @media (max-width: $NormalScreen) {
                width: 49%;
            }

            .payment-box {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                padding: 12px 25px;
                position: relative;

                .checkboxs {
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;

                    .formField {
                        margin: 0;
                        height: 100%;

                        label {
                            margin: 0;
                            height: 100%;
                            border: none;

                            &::before {
                                width: 100%;
                                height: 100%;
                                border: none;
                                background-position: 95% center;
                                background-size: 15px;
                            }
                        }

                        input[type="checkbox"]:checked+label:before {
                            background-color: #e3ebf7;
                            background-image: url("../../../../../assets/images/tick-blue.svg");
                        }
                    }
                }

                .payment-icon {
                    width: 42px;
                    height: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    z-index: 1;
                    margin-right: 15px;
                }

                .formField {
                    margin-bottom: 0;
                }
            }

            .paymentname {
                position: relative;
                z-index: 1;

                h6 {
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--text);
                    font-weight: normal;
                }
            }
        }
    }
}
