@import '../../style.scss';


.add-template-editor {
    .input-fields-templates {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 880px;
        align-items: center;

        .formField {
            width: 48%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            >label {
                min-width: 130px;
                margin-bottom: 0;
            }

            .react-select__input-container {
                input {
                    width: 100% !important;
                    min-width: 100% !important;
                }
            }

            input {
                max-width: calc(100% - 130px);
            }

            >div {
                max-width: calc(100% - 130px);
                width: 100%;

                &.errorBox {
                    width: 100%;
                    max-width: 100%;
                    margin-left: 130px;
                }
            }
        }
    }

    .editor-with-accordian {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 0;
        .template-editor {
            max-width: 720px;
            width: 100%;
            border: 1px solid var(--bordercolor);
            margin-right: 15px;
            padding: 15px;

            h1 {
                font-weight: 700;
                font-size: 16px;
                color: #000;
                background: #E2EAFF;
                padding: 5px 15px;
                margin: -15px -15px 20px -15px;

                @media (max-width: $NormalScreen) {
                    font-size: 14px;
                }
            }

            .formField {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                &.add-zindex{
                    z-index: 10;
                }

                label {
                    margin-bottom: 0;
                    margin-right: 15px;
                    min-width: 85px;
                }

                .formControl,
                >div {
                    max-width: calc(100% - 100px);
                    width: 100%;
                }

                >div {
                    max-width: calc(100% - 100px);
                    width: 100%;

                    &.errorBox {
                        width: 100%;
                        max-width: 100%;
                        margin-left: 150px;
                    }
                }
            }
        }
    }


    .template-accordian {
        width: 397px;
        border: 1px solid var(--bordercolor);
        max-height: calc(100vh - 265px);
        overflow: auto;
        position: sticky;
        top: 0;

        .variable-wrap {
            padding: 15px 20px 20px 20px;
            border-bottom: 1px solid var(--bordercolor);
        }

        h4 {
            font-weight: 400;
            font-size: 14px;
            color: #242424;
            background: #E2EAFF;
            margin: -15px -20px 20px -20px;
            padding: 7px 20px;
        }

        .variable-item {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            align-items: center;
            padding-left: 35px;
            min-height: 25px;
            margin-bottom: 10px;
            cursor: pointer;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                p {
                    color: var(--blue);
                }
            }

            >span {
                position: absolute;
                top: 0;
                left: 0;
                width: 23px;
                height: 23px;
                background: #D9D9D9;
                border-radius: 3px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
            }

            p {
                font-weight: 300;
                font-size: 14px;
                color: #000;
            }
        }
    }

    .only-editor {
        position: relative;
        z-index: 0;

        .sun-editor {
            border: none;

            .se-toolbar {
                border-top: 1px solid var(--bordercolor);
                border-bottom: 1px solid var(--bordercolor);
                margin: 0 -15px;
                width: auto;
            }

            .se-wrapper {
                min-height: 25vh;
            }

            button>svg,
            .se-svg {
                font-size: 12px;
                width: 12px;
                height: 12px;
            }

            .se-btn {
                width: 25px;
            }

            .se-btn-select.se-btn-tool-font,
            .se-btn-select.se-btn-tool-size {
                width: 60px;
            }
        }
    }

    .sun-editor {
        .se-resizing-bar {
            display: none;
        }
    }


}