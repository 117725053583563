@import '../../style.scss';


.HospitalSub-tab {
    >.adminTable {
        padding: 0;
        border: none;
        background: transparent;
        border-radius: 0;

        .activeButton.right {
            border: none;
            padding-top: 0;
        }
    }

    .table {
        .ant-table-wrapper {
            min-height: 1px;
            max-height: unset;
        }
    }

    .manage-setttings-prescriptions {
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        padding: 25px;
        justify-content: space-between;

        @media (max-width: $NormalScreen) {
            padding: 15px;
        }

        .manage-settings-main-menus {
            max-width: 18%;
            width: 100%;
            padding-right: 20px;
        }

        .manage-settings-submenus {
            max-width: 38%;
            width: 100%;
            margin: 0;
            border-width: 0 1px 0 1px;
            padding: 0 15px;
            max-height: 469px;
            overflow: auto;

            @media (max-width: $NormalScreen) {
                max-width: 32%;
                max-height: 370px;
            }

            .d-flex {
                margin: auto;
                flex-wrap: wrap;

                >div {
                    padding: 0;
                    width: 100%;
                }

                .MamageSettingRadio {
                    .setting-img {
                        @media (max-width: $NormalScreen) {
                            max-width: 126px;
                            width: 100%;
                        }
                    }

                    .radio {
                        margin-bottom: 25px;

                        >label {
                            justify-content: flex-start;
                            align-items: center;
                            flex-direction: row;
                            flex-wrap: wrap;
                            text-align: left;
                            width: 100%;
                            cursor: default;

                            span.title {
                                order: -1;
                                text-align: left;
                                margin: 0 0 10px 0;
                                min-height: 1px;
                                width: 100%;
                                max-width: 100%;
                                font-size: 14px;

                                @media (max-width: $NormalScreen) {
                                    font-size: 12px;
                                }
                            }

                            .select {
                                margin-top: 0;
                                margin-left: 15px;
                                cursor: pointer;

                                svg {
                                    margin-right: 5px;
                                    width: 15px;
                                }

                                path,
                                svg {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }

        div.header-footer-preview {
            max-width: 44%;
            width: 100%;
            padding-left: 20px;
            min-height: 469px;
            position: relative;

            @media (max-width: $NormalScreen) {
                max-width: 50%;
                min-height: 370px;
                max-height: 370px;
            }

            .grey-paper {
                background-color: #fff;
                box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.09);
                height: 100%;

                .error {
                    text-align: center;
                    font-weight: bold;
                    font-size: 14px;
                    padding: 15px;
                    max-width: 320px;
                    margin: 0 auto;
                }
            }

            .footer-preview {
                position: absolute;
                bottom: 0;
                left: 20px;
                right: 0;
                z-index: 1;

                .single-tmp-footer {
                    padding: 0;
                }
            }

            .header-preview.spacing1,
            .header-preview.spacing2,
            .header-preview.spacing3,
            .header-preview.spacing4,
            .header-preview.spacing5,
            .header-preview.spacing6,
            .header-preview.spacing7,
            .header-preview.spacing8,
            .header-preview.spacing9 {
                padding: 0;
                background: rgba(69, 117, 237, 0.12);
                transition: all 0.5s ease;
            }

            .header-preview.spacing1 {
                height: 10px;
            }

            .header-preview.spacing2 {
                height: 20px;
            }

            .header-preview.spacing3 {
                height: 30px;
            }

            .header-preview.spacing4 {
                height: 40px;
            }

            .header-preview.spacing4 {
                height: 50px;
            }

            .header-preview.spacing5 {
                height: 60px;
            }

            .header-preview.spacing6 {
                height: 70px;
            }

            .header-preview.spacing7 {
                height: 80px;
            }

            .header-preview.spacing8 {
                height: 90px;
            }

            .header-preview.spacing9 {
                height: 100px;
            }

            .footer-preview.spacing1,
            .footer-preview.spacing2,
            .footer-preview.spacing3,
            .footer-preview.spacing4 {
                padding: 0;
                background: rgba(69, 117, 237, 0.12);
                transition: all 0.5s ease;
            }

            .footer-preview.spacing1 {
                height: 10px;
            }

            .footer-preview.spacing2 {
                height: 20px;
            }

            .footer-preview.spacing3 {
                height: 30px;
            }

            .footer-preview.spacing4 {
                height: 40px;
            }
        }
    }
}




.subtabWrap {
    h3 {
        font-size: 22px;
        line-height: 120%;
        color: #242424;
        margin-bottom: 20px;
        font-weight: 600;

        @media (max-width: $NormalScreen) {
            font-size: 16px;
        }

        +form {
            margin-top: 25px
        }

        &:last-child {
            margin-bottom: 25px;
        }


    }

    .add,
    .remove {
        margin-left: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        cursor: pointer;
        line-height: 0;
        margin-top: 14px;
        transition: all 0.5s ease;

        &:hover {
            transform: rotate(180deg);
            transition: all 0.5s ease;
        }
    }

    .print-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        h6 {
            margin-right: 25px;
        }
    }
}



.Hospital-generalinfo {
    >div {
        // position: relative;
        // display: flex;
        // flex-wrap: wrap;
        // padding-left: 320px;
        // margin-bottom: 15px;
        // min-height: 36px;

        &.HideMap {
            margin: 0;
            min-height: 1px;
        }

        &.uploadlogo {
            .upload {
                max-width: 340px;
                width: 100%;
                background-size: contain;
            }
        }

        @media (max-width: $NormalScreen) {

            &.print-header {
                padding-left: 0;
                flex-wrap: wrap;
                display: block;

                >label {
                    position: static;
                    width: 100%;
                }

                >div.radio-style {
                    margin-top: 10px;
                }
            }
        }

        &.active {
            >label {
                color: #242424;
            }
        }

        .formField {
            position: static;
            margin: 0;

            &.active {
                >label {
                    color: #242424;
                }
            }
        }

        // label {
        //     position: absolute;
        //     left: 0;
        //     top: 10px;
        //     text-align: left;
        // }

        .react-select__control,
        input {
            height: 42px;
            background: #fff;
            border: 1px solid #d9d9d9;
            padding: 0 15px;
            width: 340px;

            &:focus,
            &:active {
                border-color: #d9d9d9;
            }
            &.upload-file{
                height: 100%;
            }
        }

        .react-select__control {
            margin-left: 20px;
            width: 260px;

            @media (max-width: $NormalScreen) {
                width: 220px;
                margin-left: 10px;
            }
        }

        .react-select__indicators {
            filter: invert(1) brightness(0);
        }
    }

    >div.select {
        margin-top: 0;

        div.react-select__control {
            margin-left: 0;
            width: 340px;

            @media (max-width: $NormalScreen) {
                width: 280px;
            }
        }
    }

    >.print-header {
        .radio-style {
            margin-top: 32px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            .formField {
                position: relative;
                margin-right: 15px;

                &:last-child {
                    margin-right: 0;
                }

                label {
                    position: relative;
                    top: auto;
                }
            }
        }

        .moreoption {
            line-height: 100%;
            margin-bottom: 7px;

            >span {
                text-decoration: underline;
                font-size: 16px;
                line-height: 20px;
                text-align: left;
                color: #4677f0;
                cursor: pointer;
                display: block;
                line-height: 100%;

                &:hover {
                    color: #000;
                }
            }
        }
    }
}

.print-header {
    >.radio-style {
        display: flex;
        flex-wrap: wrap;

        .formField {
            margin-right: 15px;
            margin-bottom: 18px;
        }
    }
}

.action-field {
    width: 100%;

    &.right {
        text-align: right;
    }
}

.apt-flex {
    .apt-token-list {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}


.general-settings {
    .three-col {
        display: flex;
        justify-content: space-between;
        .tab-white-bg{
            max-width: 32.5%;
            margin-bottom: 15px;
            position: relative;
            padding-bottom: 70px;
            input{
                width: 100%;
            }
            .button {
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                padding: 20px;
                text-align: right;
            }
        }
    }
}