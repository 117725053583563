@import '../../style.scss';

.table.labs {
    .ant-table-wrapper {
        max-height: calc(100vh - 282px);
        min-height: calc(100vh - 282px);
        overflow: auto;
        @media (max-width: $NormalScreen) {
            max-height: calc(100vh - 252px);
            min-height: calc(100vh - 252px);
        }
    }
}