@import '../../style.scss';

.adminHeader{
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    padding: 16px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    align-items: center;
    z-index: 9;
    min-height: 75px;
    border-bottom: 1px solid var(--bordercolor);
    @media (max-width: $NormalScreen) {
        padding: 16px 20px;
    }
    .logo{
        .big-logo{
            display: block;
        }
        .small-logo{
            display: none;
        }
        img{
            width: 150px;
            @media (max-width: $NormalScreen) {
                width: 120px;
            }
        }
    }
    .logout{
        .logout-click{
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover{
                color: var(--blue);
            }
            img{
                width: 32px;
                margin-right: 8px;
            }
        }
    }
}

.logo-arrow{
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.5s ease;
    @media (max-width: $NormalScreen) {
        width: 190px;
    }
    .slide-in-arrow{
        width: 20px;
        height: 20px;
        margin-right: -7px;
        margin-top: 7px;
        cursor: pointer;
        svg{
            width: 20px;
            height: 20px;
        }
    }
}

.profile-drop{
    position: relative;
    .profile-img{
        border-radius: 50%;
        width: 42px;
        height: 42px;
        cursor: pointer;
        display: block;
        img{
            padding: 4px;
            width: 42px;
            height: 42px;
            border-radius: 50%;
        }
        &:hover{
            background-color: rgba(62, 121, 247, 0.3);
        }
    }
    .dropdown-menu{
        position: absolute;
        right: 0;
        left: auto;
        top: 100%;
        background-color: var(--white);
        padding: 15px;
        border: none;
        box-shadow: 0 10px 30px 0 rgba(31, 45, 61, 0.1);
        border-radius: 5px;
        display: block;
        visibility: hidden;
        min-width: 250px;
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.3s ease-in;
        .dropdown-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            clear: both;
            font-weight: 400;
            color: var(--text);
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            border-bottom: 1px solid var(--bordercolor);
            padding: 8px 0;
            .avatar {
                position: relative;
                display: inline-block;
                width: 36px;
                height: 36px;
                border-radius: 50px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .profile-detail{
                max-width: calc(100% - 36px);
                width: 100%;
                padding-left: 12px;
                h5{
                    font-size: 14px;
                    margin-bottom: 5px;
                    line-height: 130%;
                    font-weight: 400;
                }
                p{
                    font-size: 12px;
                    font-weight: 300;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
        .dropdown-menu-list{
            margin-top: 10px;
            .dropdown-item{
                border:none;
            }
            span{
                display: flex;
                align-items: center;
                padding: 5px 0;
                color: var(--text);
                cursor: pointer;
                svg{
                    width: 18px;
                    height: 18px;
                    margin-right: 12px;
                }
                &:hover{
                    color: var(--blue);
                }
            }
        }
    }
    &:hover {
        >.dropdown-menu {
            transform: scaleY(1);
            opacity: 1;
            visibility: visible;
        }
    }
       

}