@import '../../../style.scss';

.add-panel-modal {
    &.Add-LabTest-modal {
        .modalBox .modalMain {
            max-width: 98%;
            width: 100%;

            .modal-inner-scroll {
                max-height: calc(100vh - 140px);
                min-height: calc(100vh - 140px);
            }

            .panel-heading-table {
                >div {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                
                .ant-table-body {
                    min-height: 250px;
                }

                .ant-table-expanded-row-fixed {
                    .ant-table-body {
                        min-height: 1px;
                    }
                }

                &.select-menu-open {
                    .ant-table-body {
                        tr{
                            .react-select__menu {
                                top: auto;
                                bottom: 100%;
                                z-index: 3;
                            }
                            &.ant-table-expanded-row-level-1[style=""]{
                            + .select-menu-down:last-child{
                                .react-select__menu {
                                    top: auto;
                                    bottom: 100%;
                                }
                            }    
                            }
                            &.select-menu-down{
                                .react-select__menu {
                                    top: 100%;
                                    bottom:auto;
                                }
                            }
                        }
                    }
                }

                &.select-inner-menu-open {
                    .ant-table-expanded-row-fixed {
                        .ant-table-body {

                            tr:nth-last-child(2),
                            tr:last-child {
                                .react-select__menu {
                                    top: auto;
                                    bottom: 100%;
                                    z-index: 3;
                                }
                            }
                        }
                    }
                }
            }

            .panel-heading {
                display: flex;
                z-index: 4;
                position: sticky;
                top: -25px;
                background: #f5f8ff;
                padding: 10px 30px 15px 30px;
                margin: -25px -30px 20px -30px;

                .formField {
                    max-width: 300px;
                    width: 100%;
                    margin-right: 15px;
                    margin-bottom: 0;
                }
            }

            .add-heading {
                max-width: 300px;
                width: 100%;
                position: relative;

                .formField {
                    max-width: 100%;
                    width: 100%;
                }

                >.addheading {
                    width: 40px;
                    position: absolute;
                    right: 0;
                    top: 29px;
                    height: 40px;
                    background: var(--blue);
                    border-radius: 0px 5px 5px 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    &:hover {
                        background-color: #4865AC;
                    }

                    img {
                        filter: invert(1) brightness(100);
                    }
                }
            }
        }

        .language-table {
            .ant-table-body {
                height: auto;
            }

            table {
                tbody td {}
            }
        }

    }
}