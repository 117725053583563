

@import '../../style.scss';

.doctors-table {
    .ant-table-wrapper {
        max-height: calc(100vh - 500px);
        min-height: calc(100vh - 500px);
        overflow: auto;
        @media (max-width: $NormalScreen) {
            max-height: calc(100vh - 437px);
            min-height: calc(100vh - 437px);
        }
    }
}